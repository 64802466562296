.headerBottom {
  width: 100%;
  display: flex;
  padding-top: 2.4rem;
  position: relative;
  .moreParent {
    display: none !important;
  }
  .formHeader {
    display: flex;
    align-items: center;
    position: relative;
    width: 59rem;
    border-right: 0.1rem solid rgba(177, 177, 177, 0.78);

    .searchButtonT {
      background-color: transparent;
      border: none;
      position: absolute;
      height: 100%;
      left: 4.2rem;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 2.7rem;
        height: 2.7rem;
      }
    }

    input {
      background-color: #f5f3f3;
      height: 6.7rem;
      padding-left: 8.8rem;
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.7rem;
      color: #1f1414;
      width: 100%;
      border: none;

      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }

    &:focus-within {
      .formSearch {
        display: block;
      }
    }
  }
  .formSearch {
    position: absolute;
    left: 0;
    top: 105%;
    z-index: 11;
    background: #fafafa;
    box-shadow: 2rem 3rem 2.5rem rgba(181, 181, 181, 0.25);
    border-radius: 2rem;
    width: 100%;
    min-height: 10rem;
    max-height: 66.2rem;
    display: none;
    overflow: auto;
    .more {
      padding-top: 1rem;
    }
    .formSearchInner {
      padding: 6rem 4rem 8rem;
      .searchformAuto {
        max-height: 40vh;
        overflow: auto;
      }
      .titleSearch {
        font-style: normal;
        font-weight: 700;
        font-size: 1.7rem;
        line-height: 2.3rem;
        letter-spacing: 0.08em;
        color: #000000;
        margin-bottom: 2.4rem;
        overflow: hidden;
      }
      .searchProductCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.4rem;
        .skuTitle {
          width: 50%;
          margin-left: 2rem;
          position: relative;
          span {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-style: normal;
            font-weight: 600;
            font-size: 1.44rem;
            line-height: 155%;
            color: #121212;
            opacity: 0.6;
            width: fit-content;
            white-space: nowrap;
          }
          .outofStock {
            background-color: red;
            opacity: 1;
            display: flex;
            justify-content: flex-end;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            line-height: 144%;
            letter-spacing: 0.03em;
            color: white;
            margin: 1rem 0 0.6rem;
            white-space: nowrap;
            padding: 0.3rem;
            border-radius: 1rem;
            margin-left: 0.6rem;
          }
          div {
            display: flex;
            align-items: center;
          }
        }

        .searchCardPrice {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-style: normal;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 155%;
          color: #121212;
          opacity: 0.9;
          margin-left: 2rem;
          svg {
            width: 1.6rem;
            height: 1.6rem;
            margin-left: 0.6rem;
          }
          span {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            position: relative;
            height: auto;
            margin-left: 1.6rem;
            &::after {
              position: absolute;
              content: "";
              top: 0;
              bottom: 0;
              margin: auto;
              background-color: #ff0404;
              width: 100%;
              height: 0.1rem;
              transform: rotate(20deg);
            }
          }
        }
        .img-container {
          background: #f3f3f3;
          border-radius: 1.5rem;
          width: 8.2rem;
          height: 8.2rem;
          min-width: 8.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 155%;
          color: #121212;
          opacity: 0.9;
        }
      }
    }
  }
  .headerBottomLeft {
    width: calc(50% - 32rem);
    display: flex;
    justify-content: flex-end;
    background-color: red;
  }

  .mobileSearch,
  .mobileBottomMenu {
    display: none;
  }
  .headerBottomRight {
    width: calc(50% + 30rem);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .headerBottomRightChild {
    display: flex;
    align-items: center;
  }

  .headerButton {
    height: 6.7rem;
    width: 30rem;
    font-style: normal;
    font-weight: 800;
    font-size: 2.8rem;
    line-height: 3.8rem;
    color: #ffffff;
    background: #ff0404;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    border: none;
    z-index: 1;

    svg {
      margin-right: 2.2rem;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .HeaderBottomRightMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2.4rem;

    a {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;
      color: rgba(31, 20, 20, 0.85);
      border-right: 0.1rem solid rgba(31, 20, 20, 0.1);
      padding-right: 1.4rem;
      margin-right: 1.4rem;
      transition: 0.3s;
      &:last-child {
        border: none;
        margin-right: 0 !important;
        padding-right: 0 !important;
      }
      &:hover {
        color: rgb(255, 4, 4);
      }
    }
  }

  .menuParent {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 4;
    width: 100%;
    display: flex;
    align-items: flex-start;

    .menuMain {
      background: #ffffff;
      width: calc(50% - 32rem);
      display: flex;
      justify-content: flex-end;
      height: 100%;
      box-shadow: 1rem 0 3.2rem rgba(188, 184, 184, 0.2);
      z-index: 10;
    }

    .menuMainInner {
      width: 37.2rem;
      position: relative;
      .menuMainInnerChild {
        padding: 1rem 0 2.8rem 2.8rem;
        .menuAssetParent {
          // position: relative;
          display: flex;
          align-items: center;

          a,
          button {
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2rem;
            letter-spacing: 0.02em;
            border: none;
            color: #1f1414;
            border-bottom: 0.1rem solid rgba(242, 242, 242, 0.96) !important;
            height: 5rem;
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 2.4rem;
            transition: 0.2s;
            border-left: 0.4rem solid transparent;
            background-color: transparent;
            width: -webkit-fill-available;

            svg {
              width: 2.8rem;
              height: 2.8rem;
              margin-right: 2.1rem;
              object-fit: contain;
              transition: 0.2s;

              path {
                opacity: 0.6;
                fill: #000;
                // transition: .2s;
              }
            }

            .arrow {
              position: absolute;
              right: 1.6rem;
              width: 1.8rem;
              margin-right: 0;
              height: 1.8rem;
            }

            &:hover {
              border-left: 0.4rem solid #ff0000;
              color: #ff0000;

              svg {
                path {
                  opacity: 1;
                  fill: #ff0000;
                }
              }

              .arrow {
                path {
                  stroke: #ff0000;
                }
              }
            }
          }
          &:last-child {
            a {
              border-bottom: none !important;
            }
          }
          &:hover {
            a {
              border-left: 0.4rem solid #ff0000;
              color: #ff0000;

              svg {
                path {
                  opacity: 1;
                  fill: #ff0000;
                }
              }

              .arrow {
                path {
                  stroke: #ff0000;
                }
              }
            }
          }
          .menuAsset {
            background: #ffffff;
            border: 0.1rem solid #f3f3f3;
            box-sizing: border-box;
            // box-shadow: 0 2rem 2rem rgba(165, 160, 160, 0.15);
            height: 69.7rem;
            width: 150%;
            display: flex;
            justify-content: flex-start;
            // display: none;
            position: absolute;
            left: 100%;
            top: 0;
            transition: 0.3s all ease;
            transform: translateX(-50%);
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            .menuAssetChild {
              padding: 2.4rem 4.5rem 2.4rem;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              justify-content: center;
              width: -webkit-fill-available;

              a {
                font-style: normal;
                font-weight: 500 !important;
                font-size: 1.2rem !important;
                line-height: 1.6rem !important;
                letter-spacing: 0.02em !important;
                color: #1f1414 !important;
                opacity: 0.45 !important;
                margin-bottom: 1.6rem !important;
                width: 50% !important;
                transition: 0.3s !important;
                padding: 0 0 1.6rem !important;
                margin: 0 !important;
                border: none !important;
                border-bottom: none !important;

                height: auto !important;
                &:hover {
                  opacity: 1 !important;
                  color: #ff0000 !important;
                }
              }
              .moreParent {
                opacity: 1 !important;
                padding: 0 !important;
                margin: 0 !important;
                justify-content: flex-start !important;
              }
              .moreParent {
                position: absolute;
                bottom: 4.4rem;

                .more {
                  font-size: 1.4rem;
                  line-height: 148%;

                  svg {
                    width: 2.8rem;
                    height: 2.8rem;
                    path {
                      fill: rgb(255, 4, 4);
                    }
                  }
                }
                &:hover {
                  .more {
                    svg {
                      path {
                        fill: #000000;
                      }
                    }
                  }
                }
              }
            }
          }
          &:hover {
            .menuAsset {
              display: flex;
              transform: translateX(0);
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199.9px) {
    // display: none;
    // .formHeader {
    //   width: 40rem;
    // }
    padding: 2rem 1.6rem 0;
    width: -webkit-fill-available;
    .headerButton {
      width: 23rem !important;
    }
    .menuParent {
      .menuMain {
        width: fit-content;
        justify-content: flex-start;
      }
    }
  }
  @media (max-width: 991.9px) {
    .headerBottomLeft {
      width: calc(50% - 20rem);
    }
    .headerBottomRight {
      width: 100%;
    }
  }
  .mobileHeaderBottom {
    display: none;
  }
  .mobileMenu {
    display: none;
  }
  @media (max-width: 767.9px) {
    .skuTitle {
      margin-left: 0.6rem !important;
    }
    .img-container {
      background: #f3f3f3;
      border-radius: 1.2rem;
      width: 5.2rem !important;
      height: 5.2rem !important;
      min-width: 5.2rem !important;
    }
    .searchformAuto {
      max-height: 60vh !important;
      padding-right: 1rem;
    }
    .searchProductCard h2 {
      font-size: 1.4rem !important;
    }
    .headerBottomLeft {
      width: 100%;
    }
    .headerBottomRight {
      width: 100%;
    }
    .mobileSearch {
      display: block;
      position: absolute;
      top: 7rem;
      left: 0;
      width: 100%;
      height: 100vh;
      max-height: 100vh;
      min-height: 100vh;
      overflow: auto;
      background: #fafafa;
      box-shadow: none;
      .formSearchInner {
        padding: 1.6rem 1.6rem 4.8rem;
        .formSearchInner {
          padding: 0;

          .searchCardPrice {
            font-size: 1.4rem;
          }
        }
        .titleSearch {
          font-weight: 600;
          font-size: 1.4rem;
          margin-bottom: 2rem;
        }
      }
      .more {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .mobileSearchFormChild {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 2rem;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 1.3rem;
          line-height: 1.5rem;
          letter-spacing: 0.05em;
          color: #121212;
          opacity: 0.7;
          margin-left: 1.2rem;
          display: block;
          white-space: nowrap;
        }
      }
      .mobileSearchForm {
        position: relative;
        border: 0.1rem solid #242424;
        box-sizing: border-box;
        border-radius: 1.5rem;
        width: 100%;
        height: 4.2rem;
        overflow: hidden;
        input {
          height: 100%;
          width: -webkit-fill-available;
          padding: 0 2rem;
          font-style: normal;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 2.2rem;
          color: #121212;
          border: none;
          background-color: transparent;

          &:focus {
            &::placeholder {
              color: transparent;
            }
          }
        }
        button {
          position: absolute;
          right: 1rem;
          top: 0;
          bottom: 0;
          margin: auto;
          border: none;
          background-color: transparent;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 1.68rem;
            height: 1.68rem;
          }
        }
      }
    }
    position: relative;
    padding-top: 0;
    .headerBottomLeft,
    .headerBottomRight {
      display: none;
    }
    .mobileHeaderBottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1.4rem 0;

      .mobileMenuButton {
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        svg {
          width: 4rem;
          height: 4rem;
        }
      }
      .searchButton {
        padding: 0;
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 2.8rem;
          height: 2.8rem;
        }
      }
    }
    .logo {
      svg {
        width: 14.8rem;
        height: 3.2rem;
      }

      &.deactive {
        pointer-events: none;
        cursor: none;
      }
    }
    .arrowSub {
      position: absolute;
      top: 8rem;
      left: 1rem;
      z-index: 4;
      opacity: 0;
      svg {
        width: 3.4rem;
        height: 3.4rem;
        transform: rotate(180deg);
      }
      &.active {
        opacity: 1;
      }
    }
    .mobileMenu {
      display: block;
      width: 100%;
      height: 100%;
      background-color: #fff;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 11;

      .mobileMenuChild {
        padding: 0 0.7rem 1.4rem 1.4rem;
        height: -webkit-fill-available;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0.2rem;
        }

        &::-webkit-scrollbar-thumb {
          background: linear-gradient(180deg, #fe0b0b 0%, #ff0b0b 100%);
          border-radius: 100px;
        }
        .mobileMenuHead {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-bottom: 4rem;
          padding: 1.4rem 0;
          .closeMenu {
            background-color: transparent;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;

            svg {
              width: 4rem;
              height: 4rem;
            }
          }
          div {
            &:last-child {
              width: 2.8rem;
              height: 2.8rem;
            }
          }
        }
        .mobileMenuBody {
          overflow: auto;
          border-bottom: 0.08rem solid rgba(36, 36, 36, 0.1);
          // max-height: 36rem;
          .mobileMenuBodyParent {
            padding-top: 1.4rem;
          }
          &::-webkit-scrollbar {
            width: 0.2rem;
          }

          &::-webkit-scrollbar-thumb {
            background: linear-gradient(180deg, #fe0b0b 0%, #ff0b0b 100%);
            border-radius: 100px;
          }
        }
        .mobileMenuBodyChild {
          display: flex;
          justify-content: space-between;
          // align-items: center;
          flex-direction: column;
          margin-bottom: 2.4rem;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
        .mobileMenuBodyChildLink {
          font-style: normal;
          font-weight: 600;
          font-size: 2rem;
          line-height: 125.1%;
          color: #000000;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          margin-bottom: 1.6rem;
          .a {
            font-style: normal;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 2rem;
            letter-spacing: 0.02em;
            color: #1f1414;
          }
          div {
            display: flex;
            align-items: center;
          }
          svg {
            max-width: 2.4rem;
            height: 2.4rem;
            padding-right: 1.4rem;
            transition: 0.4s;
            path {
              fill: #000000;
            }
          }

          &.link {
            padding-left: 4rem;
            // &:first-child {
            //   padding-left: 0;
            // }
          }
        }
        .mobileMenuFooter {
          padding: 2.6rem 0;
          border-bottom: 0.08rem solid rgba(36, 36, 36, 0.1);

          a {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.1rem;
            color: #1f1414;
            margin-bottom: 2.2rem;
            svg {
              width: 2.6rem;
              height: 2.6rem;
              margin-right: 1.4rem;
              path {
                fill: #fe0b0b;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .mobileMenuFooterBottom {
          padding: 2.4rem 0 3.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            font-style: normal;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 1.6rem;
            letter-spacing: 0.05em;
            color: #1f1414;
            opacity: 0.35;
            border: none;
            padding: 0;
            background-color: transparent;
            margin-right: 2.4rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .mobileMenuSocial {
          display: flex;
          align-items: center;
          justify-content: space-around;

          a {
            svg {
              width: 3.2rem;
              height: 3.2rem;
            }
          }
        }
        .mobileMenuBodyParent {
          .menuAssetParent {
            .mobileMenuInner {
              padding: 0 0 0 3.6rem;
              height: 0;
              transition: 0.3s;
              opacity: 0;
              // display: none;
              overflow: hidden;
              transform: translateX(100%);
              transition: 0.4s;
              position: absolute;
              top: 0;
              background: #fff;
              width: -webkit-fill-available;
              left: 0;
              a {
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: #1f1414 !important;
                opacity: 0.45;
                margin-bottom: 2.2rem;
                transition: 0.3s;
                display: none;
                width: fit-content;
              }
              .logo {
                opacity: 1 !important;
                margin-bottom: 0 !important;
              }
              // .mobileMenuHead {
              //   padding: 1.4rem 0;
              // }
              .mobileMenuInnerHeader {
                display: flex;
                align-items: center;
                border-bottom: 0.1rem solid #dbdee8;
                padding: 1.4rem 0;
                margin-bottom: 3.4rem;
                margin-left: 0;
                padding-left: 0 !important;
                svg {
                  height: 2.4rem;
                  margin-right: 1.2rem;
                  width: 2.4rem;
                  path {
                    fill: #000;
                  }
                }

                .arrowMenu {
                  width: 3rem;
                  height: 3rem;
                  margin-right: 2.4rem;

                  svg {
                    transform: rotate(180deg);
                    height: 2.8rem;
                    width: 2.8rem;
                  }
                }
                a {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 1.5rem;
                  line-height: 2rem;
                  letter-spacing: 0.02em;
                  color: #1f1414;
                  margin-bottom: 0;
                  opacity: 1;
                  white-space: nowrap;
                }
              }
              .mobileMenuInnerBody {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                height: 65vh;

                a {
                  width: 50%;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 1.2rem;
                  line-height: 1.5rem;
                  letter-spacing: 0.02em;
                  color: #565f76;
                  margin-bottom: 1.2rem;
                }
              }
            }
            &:hover {
              .arrowMenu {
                svg {
                  transform: rotate(90deg);
                }
              }

              .mobileMenuInner {
                opacity: 1;
                height: 100%;
                overflow: auto;
                position: absolute;
                top: 0;
                background: #fff;
                width: -webkit-fill-available;
                left: 0;
                padding: 0 1.4rem;
                z-index: 3;
                // display: block;
                transform: translateX(0);
                &::-webkit-scrollbar {
                  width: 0.2rem;
                }

                &::-webkit-scrollbar-thumb {
                  background: linear-gradient(180deg, #fe0b0b 0%, #ff0b0b 100%);
                  border-radius: 100px;
                }

                a {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .mobileBottomMenu {
      background: #f8f8f8;
      border: 0.08rem solid #f8f8f8;
      box-sizing: border-box;
      box-shadow: 0 0.8rem 2rem rgba(196, 196, 196, 0.2);
      border-radius: 3rem;
      position: fixed;
      left: 0.6rem;
      right: 0.6rem;
      margin: auto;
      bottom: 1.2rem;
      z-index: 11;
      display: block;
      height: 6.8rem;
      .mobileBottomMenuInner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4.2rem;
        height: 100%;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          svg {
            width: 2.8rem;
            height: 2.8rem;
          }
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.3rem;
            color: #121212;
            margin-top: 0.4rem;
            display: block;
          }

          &:first-child {
            path {
              fill: #2fb12f;
            }
          }
          &:nth-child(3),
          &:nth-child(4) {
            path {
              fill: #fe0b0b;
            }
          }
        }
      }
    }
  }
  .notFoundSearch {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 148%;
    letter-spacing: 0.05em;
    color: #000000;
    padding: 3.2rem;
  }
  .searchProductCard {
    h2 {
      font-size: 1.6rem;
    }
  }
}
@media (max-width: 1249.9px) {
  .headerButton {
    width: 23rem !important;
  }
}

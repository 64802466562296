.auth {
  padding: 1.2rem 0 8.2rem;

  .authContainer {
    margin-top: 5.4rem;
    background: #f7f7f7;
    border: 0.1rem solid #ebebeb;
    box-sizing: border-box;
    border-radius: 3rem;
    padding: 4rem 12rem 0;

    .input-group {
      position: relative;
      margin-bottom: 1rem;

      input {
        border: 0.1rem solid #e3e3e3;
        background-color: transparent;
        box-sizing: border-box;
        border-radius: 1.5rem;
        height: 4.8rem;
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2rem;
        letter-spacing: 0.05em;
        color: #121212;
        opacity: 1;
        width: 100%;
        padding-left: 6.4rem;

        &::placeholder {
          opacity: 0.7;
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: 2.2rem;
        top: 0;
        bottom: 0;
        margin: 15px 0;
        background-position: center;
        width: 2.4rem;
        height: 2.4rem;
        background-size: contain;
      }
    }
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4.4rem;
    letter-spacing: 0.05em;
    color: #121212;
    margin-bottom: 3.2rem;
  }

  .sendToEmail {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: 0.05em;
    color: #2d2c2d;
    padding: 2.8rem 0 6.4rem;
    display: block;
  }

  .authagree {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 164.4%;
    text-align: center;
    letter-spacing: 0.1em;
    color: #1f1414 !important;
    display: inline-block;
    margin-bottom: 2.4rem;

    a {
      color: #1f1414 !important;
      font-weight: 600;
      display: inline-block;
      margin-left: 0.6rem;
      transition: 0.3s;
      &:hover {
        color: rgb(255, 4, 4) !important;
      }
    }
  }

  @media (max-width: 767.9px) {
    .title {
      font-size: 2.4rem;
      line-height: 3.3rem;
      text-align: center;
    }

    .authContainer {
      padding: 4rem 1.2rem 3.2rem;
      margin-top: 0;

      .authContainerHeader {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          font-size: 2.4rem;
          line-height: 3.3rem;

          // &:last-child {
          //     padding-left: 0 !important;
          // }
        }
      }
    }
  }

}

.productsPage {
  padding: 1.2rem 0 7rem;

  .subCategory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 3.8rem;
    .allproductSee {
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 148%;
      color: #ff0404;
    }
  }
  .mobileFilterHead,
  .filterOpen {
    display: none;
  }
  .productsMoreParent {
    display: flex;
    align-items: center;
    justify-content: center;

    .productsMore {
      border: 0.1rem solid #000000;
      width: 15.6rem;
      height: 15.6rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 148%;
      color: #000000;
      background-color: transparent;
      margin-top: 1.6rem;
    }
  }
  .productsHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 3.8rem;
    border-bottom: 0.1rem solid #eeeeee;

    .productHeaderTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 125.1%;
      letter-spacing: 0.05em;
      color: #000000;
      display: flex;
      flex-direction: column;
      padding-bottom: 1.6rem;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 148%;
        letter-spacing: 0.05em;
        color: #1f1414;
        opacity: 0.4;
      }
    }

    .css-b62m3t-container {
      margin-left: 2.4rem;
      z-index: 5 !important;
    }

    .filter__control {
      position: relative;

      svg {
        width: 2.8rem;
        margin-right: 0.8rem;
        height: 2.8rem;

        g {
          opacity: 1;
        }

        path {
          fill: #000000;
          transition: 0.3s;
        }

        #Star {
          path {
            stroke: #000000 !important;
          }
        }
      }

      .filter__value-container {
        height: 4.8rem;

        .filter__input-container {
          position: absolute;
          height: 4.8rem;

          input {
            height: 100%;
          }
        }
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    .productHeaderFilter {
      display: flex;
      justify-content: flex-end;

      .productHeaderGrid {
        background-color: transparent;
        border: none;

        svg {
          width: 2.4rem;
          height: 2.4rem;

          path {
            fill: rgba(0, 0, 0, 0.1);
          }
        }

        &.active {
          svg {
            path {
              fill: #004ceb;
            }
          }
        }
      }
    }
  }

  .productsFilter {
    margin-right: 2.4rem;
  }

  .filterInputsection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .filterInputsSectionChild {
      display: flex;
      align-items: center;
    }

    input {
      border: 0.1rem solid #cecdcd;
      box-sizing: border-box;
      border-radius: 1.5rem;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.4rem;
      text-align: center;
      letter-spacing: 0.05em;
      color: #12121260;
      height: 3.8rem;
      width: 9rem;
      opacity: 1;

      &::placeholder {
        color: #12121260;
      }
    }

    span {
      font-weight: 600;
      font-size: 1.2rem;
      color: #121212;
      opacity: 0.6;
      display: block;
      margin: 0 0.8rem;
    }

    button {
      border: 0.1rem solid #f0f0f0;
      box-sizing: border-box;
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: transparent;
      width: 4.4rem;
      height: 3.8rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        opacity: 0.4;
      }
    }
  }

  .filterSearch {
    display: flex;
    align-items: center;
    position: relative;
    border: 0.1rem solid #cecdcd;
    box-sizing: border-box;
    border-radius: 1.5rem;
    height: 3.8rem;
    overflow: hidden;
    margin-bottom: 1.8rem;

    input {
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      letter-spacing: 0.05em;
      color: #121212;
      background-color: transparent;
      border: none;
      height: 100%;
      padding: 0 4rem 0 2.2rem;
      width: -webkit-fill-available;

      &::placeholder {
        color: #12121260;
      }
    }

    button {
      position: absolute;
      right: 0;
      opacity: 0.45;
      height: 100%;
      padding: 0;
      width: 4rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;
      border: none;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  .filter-content {
    // max-height: 16rem;
    // overflow-y: auto;
    // overflow-x: hidden;
    max-height: auto;

    &::-webkit-scrollbar {
      width: 0.2rem;
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #ff0404 0%, #ff0404 100%);
      border-radius: 100px;
    }
  }

  .productsParent {
    padding: 4rem 0;
  }

  .productFilterSection {
    // border-top: .1rem solid #EEEEEE;
    // padding-left: 1.6rem;

    .productFilterSectionChild {
      padding: 2.8rem 0;
      border-top: 0.1rem solid #eeeeee;

      &:first-child {
        border-top: none;
        padding-top: 0 !important;
      }

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.2rem;
        letter-spacing: 0.05em;
        color: #000000;
        margin-bottom: 1.6rem;

        svg {
          display: none;
        }
      }
    }
  }

  .productTop {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.3rem;
    letter-spacing: 0.05em;
    color: #000000;
  }

  .categroyFilter {
    overflow: auto;
    .filterCheckbox {
      padding: 0;
    }
  }

  .productCard {
    .productCardHead {
      height: 20rem;

      .swiper-wrapper{
        align-items: center;
      }

      .swiper{
        height: 210px;
      }

      img {
        height: 100%;
        width: 100%;
      }

      .discount {
        height: 2rem;
        font-size: 1.2rem;
        top: 1.7rem;
        left: 2.1rem;
        z-index: 1;
      }

    }

    .productCardBody {
      h6 {
        font-size: 1.3rem;
      }

      .price {
        font-size: 2.5rem;

        svg {
          width: 1.6rem;
          height: 1.3rem;
        }
      }
    }

    .productCardLayer {
      .productCardLayerInner {
        a {
          svg {
            width: 4.8rem;
            height: 4.8rem;
          }
        }
      }
    }
  }

  .grid-row {
    .productCard {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 2.2rem 0;
      .like-button:checked + .clickDone svg:nth-child(2) {
        top: 0.6rem;
        right: 0.6rem;
      }
      .ProductCardButtons {
        height: 4.8rem;
        width: 4.8rem;
      }
      .discount {
        top: 1.6rem;
        left: 2rem;
      }
      .productCardPrice {
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem 0 0;
        .price {
          display: block;
        }
      }
      .badge {
        right: auto;
      }

      .productCardHead {
        position: static;
        height: 14rem;
        min-width: 18rem;
        width: 18rem;
      }

      .productCardBody {
        padding: 0;
        border-right: 0.1rem solid #e5e1e1;
        min-height: 16rem;
        width: 100%;
        height: auto;
        h6 {
          font-size: 1.8rem;
          line-height: 155%;
          height: 2.4rem;
          width: 80% !important;
        }
        p {
          display: -webkit-box;
        }
      }

      .productCardLayer {
        position: static;
        width: 30%;
        min-width: 23rem;
        opacity: 1;
        height: 100%;
        transform: none !important;
        height: 14rem;
        justify-content: center;
        align-items: flex-end;
        flex-direction: row;
        background-color: transparent;
      }

      .productCardLayerInner {
        position: absolute;
        width: 70%;
        height: 100%;
      }
    }
  }

  @media (max-width: 767.9px) {
    padding: 1.2rem 0 0;
    .filterOpen {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60%;
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.5rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #000000;
      margin-left: 1rem;
      border: 0.1rem solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 3rem;
      background-color: transparent;
      svg {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.8rem;
      }
    }
    .productsHeader {
      border-bottom: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
      .css-b62m3t-container {
        margin-left: 0;
        width: 100%;
        border: 0.1rem solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 3rem;

        .filter__indicator {
          display: none;
        }
        .filter__value-container,
        .filter__input-container {
          border-radius: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .filter__single-value {
        font-size: 1.2rem;
        line-height: 1.4rem;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #000000;
      }
      .productHeaderFilter {
        width: 100%;

        .filter__control {
          width: 100%;
          border-radius: 3rem;
          background-color: transparent;
          svg {
            width: 1.8rem;
            height: 1.8rem;
          }
        }
        .filter__menu {
          z-index: 4;
        }
      }
      .productHeaderGrid {
        display: none;
      }

      .productHeaderTitle {
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 125.1%;

        span {
          font-size: 1.3rem;
          line-height: 148%;
          margin-top: 0.4rem;
          opacity: 1;
        }
      }
    }

    .productCard {
      .productCardHead {
        height: 15rem;


        .discount {
          top: 24.6rem;
          right: 1.2rem;
          left: auto;
        }
      }

      .productCardBody {
        padding: 0 0.6rem 1rem 0.8rem;

        h6 {
          font-size: 1.1rem;
          line-height: 155%;
          height: 3rem;
        }

        .productCardPrice {
          display: flex;
          align-items: center;

          .price {
            span {
              display: none;
            }
          }
        }
      }

      .productCardBottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: -webkit-fill-available;
        padding: 0 1rem 2rem;

        svg {
          width: 4.6rem;
          height: 4.6rem;
        }
      }
    }

    .productsParent {
      padding-top: 0;

      .productTop {
        font-size: 2rem;
        line-height: 2.7rem;
      }
    }

    .productsFilter {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      overflow: auto;
      background-color: #fafafa;
      z-index: 11;
      display: none;
      .mobileFilterHead {
        padding: 2.4rem 1.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          padding: 0;
          border: none;
          background-color: transparent;
          svg {
            width: 4.2rem;
            height: 4.2rem;
          }
        }
        .title-filter {
          font-style: normal;
          font-weight: 700;
          font-size: 2.4rem;
          line-height: 125.1%;
          letter-spacing: 0.05em;
          color: #000000;
          display: flex;
          align-items: center;
          svg {
            width: 2.8rem;
            height: 2.8rem;
            margin-right: 0.8rem;
          }
        }
        .clear {
          font-style: normal;
          font-weight: 400;
          font-size: 1.3rem;
          line-height: 1.5rem;
          letter-spacing: 0.05em;
          color: #121212;
          opacity: 0.7;
        }
      }
      .productFilterSection {
        padding: 0 1.6rem;

        .productFilterSectionChild {
          border-top: none;
          padding: 1.4rem 0 !important;
          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
            svg {
              display: block;
              width: 2.4rem;
              transition: 0.3s;

              height: 2.4rem;
            }
          }
          .filter-content {
            height: 0;
            // margin-top: 1.2rem;
            transition: 0.3s;
          }
          .filterInputsection,
          .filterSearch {
            display: none;
          }

          &.active {
            .title {
              margin-bottom: 1.2rem;
              svg {
                transform: rotate(90deg);
              }
            }
            .filter-content {
              height: 16rem;
              border-bottom: 0.1rem solid #eeeeee;
            }
            .filterInputsection,
            .filterSearch {
              display: flex;
            }
          }
        }
      }
      &.active {
        display: block;
      }
    }
  }
}

.cart {
  padding: 1.2rem 0 7.2rem;
  background-color: #fafafa;

  .empty-icons svg, .filled-icons svg{
    width: 2rem !important;
    height: 2rem !important;
  }
  .cartEmpty {
    padding: 5.6rem 0;

    .cartEptyContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 47.8rem;
          height: 35.8rem;
        }
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 125.1%;
        letter-spacing: 0.05em;
        color: #40605c;
        opacity: 0.35;
      }
    }
  }
  .bonusCheckbox {
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 2.4rem !important;
      line-height: 1.9rem !important;
      letter-spacing: 0.08em;
      color: #121212;
    }
  }
  .cart-row {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .cart-left {
      width: calc(100% - -10rem);
      background-color: #fafafa;
      display: flex;
      justify-content: flex-end;
      padding: 0 1.6rem;

      .card-left-inner {
        max-width: 67rem;
        width: 100%;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 2.8rem;
          line-height: 125.1%;
          letter-spacing: 0.05em;
          color: #000000;
          margin: 3.8rem 0;
          display: flex;
          flex-direction: column;

          span {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 148%;
            letter-spacing: 0.05em;
            color: #000000;
            margin-top: 0.4rem;
          }
        }
      }

      .cartSection {
        overflow: auto;
        max-height: 70rem;
        padding-right: 2.4rem;
        margin-right: 4.8rem;

        &::-webkit-scrollbar {
          width: 0.2rem;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border-right: 0.1rem solid rgba(18, 18, 18, 0.1);
          width: 0;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: linear-gradient(180deg, #fe0b0b 0%, #ff0b0b 100%);
          border-radius: 1rem;
        }
      }

      .cartCard {
        background: #f0eeee;
        border-radius: 3rem;
        padding: 1.6rem 2.4rem 1.8rem 1.6rem;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 2rem;
        .quantity-display {
          padding: 0;
          margin: 0;
        }
        .quantity-picker {
          border: none;
          display: flex;
          align-items: center;
          margin-right: 0;
        }
        .discount {
          position: relative;
          left: 1.6rem;
          height: 2.8rem;
          font-size: 1.4rem;
          margin-bottom: 20px;
        }

        .img-container {
          width: 4rem;
          height: auto;
          min-width: 10rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .trash {
          position: absolute;
          right: 2.4rem;
          top: 2.4rem;
          border: none;
          background-color: transparent;
          padding: 0;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        .cartCardDetail {
          margin-left: 3.2rem;
          width: 100%;

          h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 155%;
            letter-spacing: 0.05em;
            color: #121212;
            opacity: 0.75;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            width: 72%;
          }

          .rating {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 2.4rem;

            svg {
              width: 1.05rem;
              height: 1.05rem;
              margin-right: 0.2rem;
              opacity: 0.4;
              stroke: #121212;
            }
          }

          .cartCardDetailBottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // width: 90%;

            .priceCard {
              font-style: normal;
              font-weight: 500;
              font-size: 2.8rem;
              line-height: 135.9%;
              letter-spacing: 0.05em;
              color: #1f1414;
              display: flex;
              align-items: center;
              svg {
                margin-left: 1.2rem;
                width: 2.2rem;
                height: 1.8rem;

                g {
                  opacity: 1;
                }
              }

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 1.6rem;
                position: relative;
                margin-left: 1.4rem;

                &::after {
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 0.1rem;
                  background-color: #fe0303;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                }

                svg {
                  width: 1.4rem;
                  height: 0.9rem;
                  margin-left: 0.4rem;
                }
              }
            }

            .quantity {
              width: 12.8rem;
              height: 4.8rem;
              padding: 0 1rem;
              border: 0.1rem solid #121212;
              margin-right: 0;
              input {
                font-size: 1.8rem;
                line-height: 2.5rem;
                border: none;
              }
            }
          }
        }
      }
    }

    .cart-right {
      margin-top: -1.2rem;
      background: #ffffff;
      box-shadow: 2.5rem 7.5rem 25rem rgba(117, 107, 107, 0.1);
      border-bottom-left-radius: 3rem;
      width: calc(100% + -10rem);

      .cart-right-inner {
        max-width: 57rem;
        // width: 100%;
        // display: flex;
        // justify-content: flex-start;
        padding: 3.5rem 4.2rem;

        // .react-tabs {
        //   width: fit-content;

        .newPerson {
          padding: 5.8rem 0 0;

          .authContainerHeader {
            border-bottom: none;
            margin-bottom: 1.6rem;
          }
        }

        // .react-tabs__tab-list {
        //   width: 100%;
        //   display: flex;
        //   align-items: center;

        //   .react-tabs__tab {
        //     font-style: normal;
        //     font-weight: 600;
        //     font-size: 1.6rem;
        //     line-height: 2.2rem;
        //     text-align: center;
        //     letter-spacing: 0.05em;
        //     color: #121212;
        //     opacity: 0.35;
        //     padding-bottom: 2rem;
        //     width: 100%;
        //     outline: none;
        //     border: none;
        //     background-color: transparent;

        //     &:focus:after {
        //       display: none;
        //     }

        //     &.react-tabs__tab--selected {
        //       opacity: 1;
        //       border-bottom: 0.2rem solid #ff0b0b;
        //     }

        //     &:last-child {
        //       padding-left: 8.2rem;
        //       padding-right: 1.6rem;
        //     }
        //   }
        // }

        // .react-tabs__tab-panel {
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   flex-direction: column;
        //   width: 100%;
        // }
        .newPerson {
          // width: 70%;

          h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 2.5rem;
            letter-spacing: 0.08em;
            color: #121212;
            margin-bottom: 2.4rem;
            display: flex;
            align-items: center;
            white-space: nowrap;

            span {
              font-style: normal;
              font-weight: 400;
              font-size: 1.3rem;
              line-height: 1.8rem;
              color: #000000;
              opacity: 0.6;
              margin-left: 1.6rem;
              display: block;
              white-space: nowrap;
            }
          }

          .input-group {
            width: 100%;
            margin-bottom: 1.6rem;

            input {
              border: 0.1rem solid #e3e3e3;
              box-sizing: border-box;
              border-radius: 1.5rem;
              font-style: normal;
              font-weight: 500;
              font-size: 1.5rem;
              line-height: 2rem;
              letter-spacing: 0.05em;
              color: #121212;
              height: 4.8rem;
              padding-left: 6.4rem;
              width: 100%;

              &::placeholder {
                opacity: 0.6;
              }
            }

            textarea {
              padding: 2.4rem;
              background-color: transparent;

              &::placeholder {
                opacity: 0.6;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .filterRadio span {
          font-style: normal;
          font-weight: 600;
          font-size: 2rem;
          letter-spacing: 0.05em;
          color: #121212;
          opacity: 0.86;
        }

        .save-Info {
          margin-top: 4.4rem;

          label {
            margin-bottom: 1rem;

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 2rem;
              letter-spacing: 0.05em;
              color: #121212;
              margin-top: 0;
            }
          }

          .saveInfoChild {
            padding-left: 3.2rem;
            width: 70%;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 1.3rem;
              line-height: 155%;
              letter-spacing: 0.04em;
              color: rgba(0, 0, 0, 0.6);
              margin-bottom: 0.6rem;
            }

            a {
              font-style: normal;
              font-weight: 600;
              font-size: 1.3rem;
              line-height: 164.4%;
              letter-spacing: 0.1em;
              color: #121212;
              padding-bottom: 0.3rem;
              border-bottom: 0.06rem solid #121212;
              width: fit-content;
            }
          }
        }
      }
    }
    .address {
      padding-bottom: 0;
    }
    .newPersonLogin {
      .input-group {
        margin-bottom: 3.2rem !important;
      }
      .xl-6 {
        &:first-child {
          display: none;
        }
      }
    }
    .orderComplated {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.02em;
        color: rgba(18, 18, 18, 0.75);
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.7);
        transition: 0.3s;

        &:hover {
          color: #121212;
          border-bottom: 0.1rem solid #000;
        }
      }
    }
    .cartTotalParent {
      width: 92%;
      margin-right: 0;
    }
    .cartTotalParent {
      margin-top: 1.6rem;
      margin-right: 8rem;
    }

    .cartTotal {
      background: #ffffff;
      border: 0.1rem solid #f5f5f5;
      box-sizing: border-box;
      border-radius: 1.5rem;
      padding: 0 5.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 12rem;
      margin-bottom: 1.6rem;
      // margin-right: 1.6rem;
      p {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.08em;
        color: rgba(18, 18, 18, 0.75);
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          display: flex;
          margin-left: 3.4rem;
          font-style: normal;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 1.9rem;
          letter-spacing: 0.08em;
          color: #121212;
          align-items: center;

          svg {
            width: 1.2rem;
            height: 0.8rem;
          }
        }
      }

      .CarttotalPrice {
        width: 40%;
        border-right: 0.1rem solid #e7e7e7;
      }

      .cartTotalChild {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        // padding-left: 5rem;

        p {
          font-style: normal;
          font-weight: 500;
          font-size: 2.4rem;
          line-height: 2.8rem;
          text-transform: uppercase;
          color: rgba(18, 18, 18, 0.75);

          span {
            font-size: 2.4rem;
            line-height: 2.8rem;
            display: flex;
            align-items: center;
            margin-left: 2.4rem;

            svg {
              width: 2.4rem;
              height: 1.8rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1023.9px) {
    .cart-left {
      padding: 0 1.6rem;
    }
  }
  @media (max-width: 767.9px) {
    padding-bottom: 4.8rem;
    .bonusCheckbox span {
      font-size: 1.6rem !important;
    }
    .img-container {
      height: auto;
      min-width: 7rem !important;
    }
    .cart-row {
      display: flex;
      flex-direction: column;
      .react-tabs {
        width: 100% !important;
      }
      input {
        background-color: transparent;
      }
      .cart-left {
        width: -webkit-fill-available !important;
        .card-left-inner {
          width: 100%;
          max-width: 100%;
        }
        .title {
          margin: 2.4rem 0 4rem !important;
          span {
            display: none;
          }
        }
        .cartSection {
          height: auto;
          max-height: fit-content;
          margin-right: 0;
          padding-right: 0;
        }
        .cartCard {
          padding: 1.4rem 1.5rem 0.6rem;
          .trash {
            right: 1.4rem;
            top: 1.4rem;
          }
          .cartCardDetail {
            margin-left: 1.2rem;

            .cartCardDetailBottom {
              display: flex;
              flex-direction: row-reverse;
            }
            h2 {
              margin-bottom: 0.8rem;
            }
            .rating {
              margin-bottom: 1.6rem;
            }
            .priceCard {
              display: flex;
              align-items: center;
              font-size: 1.5rem !important;
              span {
                display: flex;
                align-items: center;
                font-size: 1.5rem;
              }
              svg {
                width: 1.4rem !important;
                height: 1.4rem !important;
                margin-left: 0.3rem !important;
              }
            }

            .cartCardDetailBottom {
              .quantity {
                width: 8rem !important;
                height: 2.8rem !important;
                border: 0.1rem solid #06b155;
                padding: 0;

                input {
                  font-size: 1.2rem;
                  line-height: 1.9rem;
                  color: #06b155;
                  margin: 0 0.1rem;
                  width: 4rem !important;
                }
                button {
                  color: #06b155;
                  width: 1rem;
                }
              }
            }
          }
        }
        .cartTotalChild {
          width: 55% !important;
          p {
            font-size: 1.6rem !important;
            span {
              font-size: 1.6rem !important;
              svg {
                width: 1.6rem !important;
                height: 1.6rem !important;
              }
            }
          }
        }
      }

      .cartTotalParent {
        margin-top: 0;
        margin-right: 0 !important;
        width: 100% !important;
      }
      .cartTotal {
        padding: 0 1.4rem;
        height: 10rem;
        .CarttotalPrice {
          width: 45%;
        }
        p {
          font-size: 1.2rem !important;
          span {
            margin-left: 1.4rem !important;
            font-size: 1.2rem !important;
          }
        }
      }
      .cart-right {
        margin-top: 0;
        background-color: transparent;
        // margin-top: 4.2rem;
        padding: 0 1.6rem;
        box-shadow: none;
        width: -webkit-fill-available;
        border-radius: 0;
        .cart-right-inner {
          max-width: 100%;
          padding: 0;
          width: 100%;

          .react-tabs {
            .react-tabs__tab {
              padding-bottom: 1.6rem;
            }
            .react-tabs__tab-panel {
              .newPerson {
                width: -webkit-fill-available;
                padding: 0 1.6rem;
                margin-top: 4rem;

                h2 {
                  margin-bottom: 2rem;
                  white-space: pre-wrap;
                }
              }
            }
          }
        }
      }
      .react-tabs__tab-list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 0 1.6rem;
        width: -webkit-fill-available !important;
      }
      .react-tabs__tab {
        padding: 0 !important;
        border-bottom: 0.2rem solid transparent;
        font-size: 1.8rem;
        line-height: 2.5rem;
        &:first-child {
          margin-right: 1rem;
        }
      }
    }
    .green-button.desk-none {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2.8rem;
      width: 100%;
    }
  }
}

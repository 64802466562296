.contact {
  padding: 1.2rem 0 7.6rem;

  .row {
    margin-top: 2.8rem;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 125.1%;
    color: #000000;
    margin-bottom: 3.2rem;
    margin-top: 2.8rem;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 148%;
      display: block;
      margin-top: 0.4rem;
    }
  }

  .input-group {
    width: 100%;
    margin-bottom: 1.6rem;

    input {
      border: 0.1rem solid #e3e3e3;
      box-sizing: border-box;
      border-radius: 1.5rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0.05em;
      color: #121212;
      height: 4.8rem;
      padding-left: 6.4rem;
      width: 100%;

      &::placeholder {
        opacity: 0.6;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .socialIcons {
    display: flex;
    align-items: center;
    margin-top: 4rem;

    a {
      margin-right: 2.4rem;

      svg {
        width: 3.2rem;
        height: 3.2rem;

        g {
          opacity: 1;
        }
      }
    }
  }

  .contactInner {
    margin-left: 1.6rem;

    .map {
      height: 50rem;
      width: 100%;

      iframe {
        width: 100% !important;
        height: 100% !important;
        border: none;
      }
    }
  }
  .map {
    height: 50rem;
    width: 100%;

    iframe {
      width: 100% !important;
      height: 100% !important;
      border: none;
    }
  }
  .contactHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 3.2rem;

    .title {
      margin: 0;
    }

    .contactDetail {
      display: flex;
      flex-direction: column;

      div,
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.5rem;
        color: #1f1414;
        display: flex;
        align-items: center;
        margin-bottom: 0.6rem;

        svg {
          width: 2.2rem;
          height: 2.2rem;
          margin-right: 1.2rem;

          g {
            opacity: 1;
          }
        }
      }
    }
  }
  @media (max-width: 767.9px) {
    .row {
      margin-top: 0;

      .title {
        font-size: 2.4rem;
        line-height: 3.3rem;

        span {
          font-size: 1.4rem;
        }
      }
      .socialIcons {
        margin-bottom: 2.4rem;
        justify-content: center;
      }
      .contactHeader {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .contactDetail {
        margin-top: 2.4rem;
      }
    }
  }
}

.about {
  padding: 1.2rem 0 8.2rem;
  background: #fafafa;

  .breadCrumb {
    margin-bottom: 2.4rem;
  }
  .aboutparent {
    // padding: 2.4rem 0;
    margin-bottom: 6.4rem;

    .aboutCard {
      width: 67%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &.aboutCardTop {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: auto;
        padding-bottom: 6.4rem;
        p {
          text-align: justify;
          width: 100%;
        }
        div {
          width: 80%;
        }
      }
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 125.1%;
        letter-spacing: 0.05em;
        color: #000000;
        margin-bottom: 1.6rem;
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 148%;
        color: #000000;
        opacity: 0.85;
        margin-bottom: 1.6rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .img-container {
      width: 100%;
      height: 46.1rem;
      border-radius: 2rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .aboutCard.left {
      padding-left: 5rem;
    }
  }
  .aboutUpperParent {
    .aboutparent {
      &:nth-child(even) {
        grid-auto-flow: dense;
        direction: rtl;

        .left {
          padding-right: 5rem;
          padding-left: 0;
          // align-items: flex-end;
          direction: ltr;
        }
      }
      &:last-child {
        display: none;
      }
    }
  }

  .infobar {
    background: #fbfbfb;
    border-radius: 2rem;
    margin-bottom: 3.8rem;

    .container {
      padding: 0;
    }
  }
  .aboutmeParent {
    .aboutme {
      margin-bottom: 8rem;
      display: none;
      .img-container {
        border-radius: 2rem;
        overflow: hidden;
        height: 55.4rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .aboutmeInner {
        background: #000000;
        box-shadow: 0 2rem 2rem rgba(204, 204, 204, 0.15);
        border-radius: 1.5rem;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 0 8.2rem;
        position: relative;
        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 3.2rem;
          line-height: 4.4rem;
          letter-spacing: 0.05em;
          color: #fdfcfc;
          margin-bottom: 2rem;
        }

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 164.4%;
          letter-spacing: 0.05em;
          color: #fdfcfc;
        }
        &::before {
          position: absolute;
          left: 2.4rem;
          top: 2.4rem;
          content: "";
          width: 5.2rem;
          height: 6rem;
          background-image: url(../../assets/dot2.svg);
          background-repeat: no-repeat;
        }
        &::after {
          position: absolute;
          right: 2.4rem;
          bottom: 2.4rem;
          content: "";
          width: 5.2rem;
          height: 6rem;
          background-image: url(../../assets/dot1.svg);
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &:last-child {
        display: grid;
      }
    }
  }

  .aboutSertifcade {
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 4.9rem;
      letter-spacing: 0.05em;
      color: #121212;
      margin-bottom: 1.9rem;
    }

    .sertifcate-card {
      padding: 0 7.7rem 0 6.9rem;
      background: #fefefe;
      box-shadow: 0 0.8rem 4rem rgba(196, 196, 196, 0.1);
      border-radius: 3rem;
      margin-bottom: 3.6rem;
      max-height: 49.2rem;
      height: 49.2rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .img-container {
        width: 100%;
        min-height: 36rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      span {
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 155%;
        text-align: center;
        letter-spacing: 0.05em;
        color: #121212;
        opacity: 0.86;
        display: block;
      }
    }
  }
  @media (max-width: 767.9px) {
    padding-bottom: 6rem;

    .aboutCardTop {
      padding-bottom: 2.4rem !important ;

      div {
        width: 100% !important;
      }
    }
    .aboutparent {
      margin-bottom: 3rem;
      .aboutCard {
        width: 100%;
        margin-bottom: 2.4rem;
        h2 {
          font-size: 2.8rem;
          line-height: 125.1%;
          color: #000000;
        }
        p {
          font-size: 1.4rem;
          line-height: 148%;
        }
      }
      .left {
        padding-right: 0 !important;
      }
      .img-container {
        height: 21.4rem;
      }
      .aboutCard.left {
        padding-left: 0;
      }
    }
    .infobar {
      margin-bottom: 3rem;
      .topInfoBar {
        background-color: #fbfbfb;
      }
    }
    .resp-about {
      display: flex;
      flex-direction: column-reverse;
    }
    .aboutmeParent {
      .aboutme {
        margin-bottom: 4.8rem;
        .aboutmeInner {
          padding: 2.4rem 2rem !important;
          margin-bottom: 1.6rem;

          .title {
            font-size: 2.6rem;
            line-height: 3.6rem;
          }
          p {
            font-size: 1.6rem;
            line-height: 164.4%;
          }

          &::before {
            left: 1rem;
            top: 1.4rem;
            width: 1.6rem;
            height: 2rem;
            background-size: contain;
          }
          &::after {
            right: 2rem;
            bottom: 1.4rem;
            width: 1.6rem;
            height: 2rem;
            background-size: contain;
          }
        }
        .img-container {
          height: 34.5rem;

          img {
            object-fit: cover;
          }
        }
      }
    }
    .aboutSertifcade {
      .title {
        font-size: 2.8rem;
        line-height: 3.8rem;
      }
    }
    .about-row-resp {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: auto;

      .sertifcate-card {
        padding: 0;
        width: 37rem;
        height: 46rem;

        .img-container {
          max-height: 36rem;
          width: 90%;
        }
      }
    }
    .brand {
      padding-top: 3.6rem !important;
      .brandHeader {
        margin-bottom: 1.6rem;
        .title {
          font-size: 2.8rem;
          line-height: 125.1%;
        }
      }
    }
  }
}

.loan-calculator {
    width: 50%;
    padding: 20px;
    background-color: #f9f9f9;
}

.input-group {
    margin-bottom: 10px;
}

.input-group label {
    display: inline-block;
    width: 210px;
    font-weight: bold;
}


.result-group {
    margin-top: 20px;
}

.result-group span {
    display: block;
}
.loan-calculator-container {
    display: flex;
    justify-content: space-between;
}

.loan-calculator {
    padding: 20px;
}

.chart-container {
    width: 50%;
    height: 350px;
    margin-top: 10px;
}

#loanChart {
    width: 100%;
}
.big-text {
    font-size: 24px;
    display: flex !important;
}

.title{
    font-size: 3.6rem;
}

.modal {
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    height: 35%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Styles for apply button */
.apply-button {
    background-color: #f44336; /* Theme color */
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.apply-button:hover {
    background-color: #f44336; /* Darker theme color on hover */
}

/* Styles for form inside modal */
.modal form {
    display: flex;
    flex-direction: column;
}

.modal form .form-group {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 10px;
    justify-content: start;
    margin-right: 20px;
}

.modal form label {
    margin-right: 10px;
}

.modal form input {
    width: 100%;
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal form button {
    background-color: #f44336; /* Theme color */
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.modal form button:hover {
    background-color: #f44336; /* Darker theme color on hover */
}
.css-qbdosj-Input{
    display: none !important;
}

/* LoanCalculator.css */
.select-field,
.input-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input-field{
    width: 96%;
}

.input-unit {
    margin-left: 5px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}
.result-div{
    padding: 15px;
    background: #e5e5e5;
    margin: 10px 0;
    font-size: 24px;
    border-radius: 10px;
}

.big2-text{
    font-size: 34px;
}

/* MonthlyPaymentsTable.css */
.payments-table {
    width: 100%;
    border-collapse: collapse;
}

.payments-table th, .payments-table td {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
}

.payments-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.payments-table td {
    font-weight: normal;
}
.table-container{
    margin: 20px 0;
}
.blue-text{
    color: #f44336 !important;
    margin:0 5px ;
}

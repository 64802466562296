.compare {
  .compareHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4rem 0 5.6rem;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-style: normal;
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 125.1%;
      color: #000000;

      svg {
        width: 3.2rem;
        height: 3.2rem;
        margin-right: 1.4rem;

        path {
          fill: #fe0b0b;
        }
      }
    }
  }
  .compareSpecs {
    border-top: 0.2rem solid transparent;

    .compareTable {
      width: 98.8%;
    }
  }
  .productCard {
    .productCardBottom {
      a {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .compareHeaderRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button,
    a {
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.8rem;
      letter-spacing: 0.08em;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0.8rem;
      }

      &.red {
        color: #fe0b0b;
        margin-left: 4.2rem;

        svg {
          width: 3.2rem;
          height: 3.2rem;
        }
      }
    }
  }

  .compareHead {
    display: flex;
    align-items: flex-start;

    .CompareTItle {
      display: flex;
      flex-direction: column;

      .result {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 125.1%;
        letter-spacing: -0.02em;
        color: #000000;
        opacity: 0.4;
        display: flex;
        align-items: flex-start !important;
        flex-direction: row !important;
        height: 31.6rem;
        span {
          display: block;
          margin-left: 0.8rem;
        }
      }

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 125.1%;
        letter-spacing: -0.02em;
        color: #000000;
        margin-bottom: 1.6rem;
      }
    }

    .compareHeadChild {
      display: flex;
      align-items: flex-start;
      overflow: auto;
      // padding-left: 0.4rem;
      padding: 0 0 2.4rem !important;
      width: 100%;

      &::-webkit-scrollbar {
        height: 0.9rem;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        border-bottom: 0.2rem solid #eee;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #fe0b0b 0%, #ff0b0b 100%);
        border-radius: 100px;
      }
    }

    .compareTable {
      display: flex;
      flex-direction: column;
      border-bottom: 0.1rem solid #eaeaea;

      .div {
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.1rem;
        letter-spacing: 0.08em;
        color: #121212;
        height: 5.6rem;
        padding: 0 2.4rem;
        display: flex;
        align-items: center;

        &:nth-child(odd) {
          background: #eeeeee;
          border-top-left-radius: 1.5rem;
          border-bottom-left-radius: 1.5rem;
        }

        // &:nth-child(even) {
        //   background: #fff;
        // }
      }
    }

    .compareCard {
      width: 30rem;
      min-width: 30rem;
      display: flex;
      flex-direction: column;
      position: relative;
      .productCard {
        margin-right: 1.6rem;
      }
      .compareDelete {
        position: absolute;
        left: 2rem;
        top: 2rem;
        width: 3.2rem;
        height: 3.2rem;
        z-index: 2;
        padding: 0;
        border: none;
        background-color: transparent;
        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
      .div {
        border-radius: 0 !important;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.08em;
        color: rgba(18, 18, 18, 0.85);
        span {
          display: none;
        }
      }
    }
  }
  @media (max-width: 767.9px) {
    .compareHeader {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title {
        font-size: 2.6rem;
        line-height: 125.1%;
        margin-bottom: 4.5rem;
        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
      .compareHeaderRight {
        display: flex;
        align-items: flex-start;
        margin: 0;
        button,
        a {
          font-size: 1.3rem;
          line-height: 1.8rem;
          margin: 0;

          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
    .CompareTItle {
      display: none !important;
    }
    .compareHead {
      .compareCard {
        margin-right: 2rem;
        min-width: 25rem;
        .productCard {
          margin-right: 0;

          .productCardBottom {
            display: flex;
            justify-content: center;
            a {
              margin-left: 1.6rem;

              &:last-child {
                margin-left: 0;
              }
            }
          }
        }
      }
      .compareTable {
        .div {
          border-radius: 1.5rem !important;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            display: block;
          }
        }
      }
    }
  }
}

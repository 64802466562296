footer {
  padding: 2.6rem;


  @media (max-width: 768px) {
    margin-top: 20px;
  }
  @media (min-width: 768px) {
    .playstore-link {
      margin-top: 30px;
    }
    .store-links {
      flex-direction: column;
      justify-content: start;

      svg {
        margin-bottom: 10px;
      }
    }
  }

  .footer-inner {
    background: #141223;
    border-radius: 2rem;

    .footerTop {
      padding: 4.5rem 0;

      .footer-logo {
        height: 5.6rem;
        margin-bottom: 1.2rem;

        svg {
          width: auto;
          height: 100%;
        }
      }

      .callButton {
        display: flex;
        align-items: center;
        margin-top: 20px;
      }

      .footerLogin {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid (rgba(223, 223, 226, 0.7));
        box-sizing: border-box;
        border-radius: 3rem;
        height: 5rem;
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 125.1%;
        color: #ffffff;
        padding: 0 3.5rem 0 2.4rem;

        svg {
          margin-right: 1.6rem;
          width: 3.5rem;
          height: 3.5rem;

          path {
            fill: #fff;
            transition: 0.3s;
          }
        }

        &:hover {
          svg {
            path {
              stroke: #ff0404;
            }
          }
        }
      }
    }

    .footerMiddle {
      margin-bottom: 3.2rem;

      .footer-ul {
        max-height: 27rem;
        overflow: hidden;
        margin-bottom: 3.2rem;

        li {
          a {
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 125.1%;
            color: #ffffff;
            opacity: 0.6;
            margin-bottom: 1.2rem;
            position: relative;
            transition: 0.3s;

            &::after {
              content: "";
              position: absolute;
              left: -1.6rem;
              width: 0.8rem;
              height: 0.8rem;
              background-color: #089147;
              border-radius: 50%;
              top: 0;
              bottom: 0;
              margin: auto;
              opacity: 0;
              transition: 0.3s;
            }

            &:hover {
              opacity: 1;

              &::after {
                opacity: 1;
              }
            }
          }

          &.title,
          &.title a {
            font-style: normal;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 125.1%;
            color: #ffffff;
            margin-bottom: 2rem;
            opacity: 1;
          }
        }
      }
    }

    .footerSearch {
      display: flex;
      flex-direction: column;

      label {
        font-style: normal;
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #ffffff;
        margin-bottom: 1rem;
      }

      form {
        display: flex;
        align-items: center;
        border: 0.1rem solid rgba(255, 255, 255, 0.7);
        box-sizing: border-box;
        border-radius: 3rem;
        padding: 0 2.4rem 0 2.8rem;
        height: 5rem;

        svg {
          width: 2rem;
          height: 2rem;
          padding-right: 1rem;

          g {
            opacity: 1;
          }

          path {
            fill: #ffffff;
          }
        }

        input {
          font-style: normal;
          font-weight: 400;
          font-size: 1.3rem;
          line-height: 1.8rem;
          height: 100%;
          width: 100%;
          background-color: transparent;
          border: none;
          color: #fff;

          &::placeholder {
            color: rgba(255, 255, 255, 0.45);
          }
        }

        button {
          display: flex;
          align-items: center;
          background-color: transparent;
          border: none;
          padding: 0;

          svg {
            width: 2.4rem;
            height: 2.4rem;
            padding-left: 0;
          }
        }
      }
    }

    .socialIconsFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100px;

      a {
        margin-right: 3.6rem;
      }

      svg {
        width: 4rem;
        height: 4rem;

        g {
          opacity: 1;
        }

        path {
          stroke: #fff;
          transition: 0.3s;
        }

        &:hover {
          path {
            stroke: #ff0404;
          }
        }
      }
    }

    .footerBottom {
      border-top: 0.1rem solid rgba(246, 246, 247, 0.2);
      padding: 2.8rem 0 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 135%;
      color: #ffffff;

      span {
        opacity: 0.45;
        display: block;
        padding-left: 1.6rem;
      }

      a {
        color: #ffffff;
        margin-left: 1.6rem;
      }
    }
  }

  @media (max-width: 767.9px) {
    padding: 1rem 0.8rem;

    .footerTop {
      padding-bottom: 3.2rem !important;

      .footer-logo {
        height: 3.2rem !important;
        margin-bottom: 1.2 !important;
      }
    }

    .footer-ul {
      margin-bottom: 3.2rem;

      li.title {
        margin-bottom: 1.6rem !important;
        font-size: 1.4rem !important;
      }
    }

    .footer-inner {
      padding-bottom: 4.2rem;

      .footerBottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      .footerSearch {
        padding: 0;
        margin-bottom: 3.2rem;
        border-left: none;

        border-right: none;
        width: 100%;
      }

      .socialIconsFooter {
        // width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        a {
          margin-right: 0;
        }
      }
    }
  }
}

.border-x {
  flex-direction: column;
  align-items: center;
  border-left: 0.1rem solid rgba(223, 223, 226, 0.1);
  border-right: 0.1rem solid rgba(223, 223, 226, 0.1);
  @media (max-width: 768px) {
    svg {
      margin: 0 10px;
    }
  }
}

footer .footer-inner .footerSearch {
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;

  label {
    margin-right: 20px;
  }
}


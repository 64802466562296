
.productDetail {
  padding: 1.2rem 0 8rem;
  .discount {
    position: relative ;
    top: 1rem;
    left: 1rem;
  }

  .addToCard:disabled {
    background: #bcbaba;
    cursor: no-drop;
  }

  .outStok {
    display: flex;
    justify-content: flex-end;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 144%;
    letter-spacing: 0.03em;
    color: red;
    margin: 2rem 0 0.6rem;
  }

  .rotingtxt {
    -webkit-transform: rotate(331deg);
    -moz-transform: rotate(331deg);
    -o-transform: rotate(331deg);
    transform: rotate(331deg);
    position: absolute;
  }

  .like-button {
    opacity: 0;
    position: absolute;
  }

  .empty-icons svg,
  .filled-icons svg {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 0;
  }

  .like-button:checked + svg {
    path {
      fill: red;
    }
  }

  .like {
    .heart {
      display: flex;
      align-items: center;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;
        letter-spacing: 0.05em;
        color: #1f1414;
      }
    }
  }

  .productRow {
    margin-top: 5.8rem;
    margin-bottom: 8rem;

    .swiper-pagination {
      display: none;
    }

    .productDetailImg {
      position: relative;
      border-radius: 3rem;
      overflow: hidden;

      .discount {
        position: absolute;
        top: 2.8rem;
        left: 2.1rem;
        z-index: 2;
        font-size: 1.4rem;
        line-height: 135.9%;
        height: 3.3rem;
      }

      .badge {
        left: 8.6rem;
        top: 2.8rem;
        width: fit-content;
      }
    }

    .mySwiper2 {
      height: 58.2rem;
      .productDetailSwiper{
        &:hover {
          cursor: zoom-in;
        }
      }
    }

    .mySwiper {
      height: 4.8rem;
      border-radius: 0;
      // .swiper-slide{
      //   border-radius: 0;
      // }
    }

    .productDetailSwiper {
      width: 99% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.1rem solid #dddcdc;
      border-radius: 3rem;
      overflow: hidden;
      height: 99%;
      background-color: #fff;
    }

    .swiper {
      .swiper-button-prev,
      .swiper-button-next {
        color: #ff0000;
      }

      .swiper-slide {
        // background: #ececee;

        img {
          object-fit: contain;
          width: 90%;
          max-height: 90%;
          height: 58.2rem;
        }
      }
    }

    .swiper-thumbs {
      margin-top: 1.4rem;
      padding: 0 1.6rem;

      .productDetailSwiper {
        height: 4.6rem;
        border-radius: 0.8rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 99% !important;
      }

      .swiper-slide {
        cursor: pointer;

        height: 4.8rem !important;

        img {
          width: 100% !important;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .productRight {
      padding-left: 3.6rem;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 144%;
        letter-spacing: 0.03em;
        color: #000000;
        margin: 0.8rem 0;
      }

      .productRightPrice {
        display: flex;
        align-items: center;
        border-bottom: 0.1rem solid #eeeeee;
        padding-bottom: 0.8rem;
        // margin-bottom: 2.4rem;

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 4.6rem;
          line-height: 135.9%;
          letter-spacing: 0.05em;
          color: #1f1414;
          display: flex;
          align-items: center;
          position: relative;
          padding-right: 1.4rem;

          svg {
            width: 4rem;
            height: 3.1rem;
            margin-left: 1rem;

            g {
              opacity: 1;
            }

            path {
              fill: #000;
            }
          }
        }

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 2rem;
          line-height: 135.9%;
          letter-spacing: 0.05em;
          color: #1f1414;
          display: flex;
          align-items: center;
          padding-left: 2rem;
          margin-top: 1.8rem;
          position: relative;

          &::before {
            content: "";
            background-color: #1f1414;
            position: absolute;
            width: 0.1rem;
            height: 3.6rem;
            left: 0.6rem;
            top: -1rem;
            transform: rotate(30deg);
          }

          &::after {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            background-color: #fb2f2f;
            width: 80%;
            height: 0.13rem;
            transform: rotate(20deg);
          }

          svg {
            width: 1.4rem;
            height: 1rem;
            margin-left: 0.4rem;
          }
        }
      }
    }

    .addToCartSection {
      padding: 2.8rem 0 2.4rem;
      display: flex;
      align-items: center;
    }

    .title,
    .addToCartTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 125.1%;
      letter-spacing: 0.05em;
      color: #000000;
      opacity: 0.65;
      margin-bottom: 0.6rem;
    }

    .productChoose {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 4.4rem;
      border-bottom: 0.1rem solid #eeeeee;

      .titleType {
        margin-bottom: 0.9rem;
      }

      .productColor {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .filterRadio {
          margin-bottom: 1.6rem !important;
          width: 4rem;
          height: 4rem;
          padding-left: 0;
          margin-right: 0.6rem;
          position: relative;

          .tooltype {
            position: absolute;
            top: 115%;
            left: 0;
            margin: auto;
            background: #eeeeee;
            border-radius: 4rem;
            height: 1.7rem;
            padding: 0 1rem;
            width: fit-content;
            opacity: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 1.1rem;
            line-height: 125.1%;
            color: #00000065;

            &::after {
              content: "";
              position: absolute;
              top: -0.4rem;
              background-color: #eeeeee;
              width: 0.6rem;
              height: 0.6rem;
              z-index: 2;
              left: 1.4rem;
              margin: auto;
              transform: rotate(45deg);
            }
          }

          &:hover {
            .tooltype {
              opacity: 1;
            }
          }

          input {
            top: 0.3rem;
            height: 4rem;
          }

          input:checked ~ .checkmark {
            border: 0.1rem solid rgba(18, 18, 18, 0.6);
          }

          .checkmark {
            width: 4rem;
            height: 4rem;
            border: solid 0.1rem transparent;

            &::after {
              width: 3.4rem;
              height: 3.4rem;
              display: block;
            }
          }

          &:nth-child(1) {
            .checkmark {
              &::after {
                background-color: green;
              }
            }
          }

          &:nth-child(2) {
            .checkmark {
              &::after {
                background-color: blue;
              }
            }
          }
        }
      }

      .filter__control {
        position: relative;

        .filter__value-container {
          height: 4.8rem;
          padding: 0 2.4rem;

          .filter__input-container {
            position: absolute;
            height: 4.8rem;

            input {
              height: 100%;
            }
          }
        }

        div {
          display: flex;
          align-items: center;

          svg {
            path {
              fill: #cb0606;
            }
          }
        }
      }
    }

    .productRightTop {
      display: flex;
      align-items: center;
      padding-top: 2.4rem;

      p {
        border-left: 0.1rem solid #eeeeee;
        padding-left: 1.8rem;
        margin-left: 1.8rem;

        span {
          font-weight: 500;
          color: #000;
        }
      }

      a {
        &:hover {
          color: #cb0606;
        }
      }

      span,
      a,
      p {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 125.1%;
        letter-spacing: 0.05em;
        color: rgba(0, 0, 0, 0.75);
        transition: 0.3s;
      }
    }

    .swap {
      display: flex;
      align-items: center;
      margin-left: 3.8rem;
      border-left: 0.1rem solid #eeeeee;
      padding-left: 3.8rem;
      border: none;
      background-color: transparent;
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #1f1414;
    }

    .buttonSection {
      display: flex;
      align-items: center;

      .like {
        .heart {
          height: 4rem;
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            path {
              stroke: #000;
              fill: #000;
            }
          }

          input {
            position: absolute;
            opacity: 0;

            &:checked + svg {
              path {
                stroke: #cb0606;
                fill: #cb0606;
              }
            }

            &:checked + p {
              color: red;
            }
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #1f1414;
      }

      svg {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 1.2rem;
      }
    }

    .shareSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 3rem;

      .share {
        display: flex;
        align-items: center;

        a {
          background: #f3f3f3;
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.8rem;

          svg {
            width: 1.9rem;
            height: 1.9rem;
          }
        }
      }

      .rating {
        display: flex;
        align-items: center;
        border-left: 0.1rem solid #eee;
        padding-left: 1.4rem;

        svg {
          margin-right: 0.6rem;
          width: 2.8rem;
          height: 2.8rem;
          // opacity: 0.45;
        }

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 3.2rem;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #bcbaba;
        }
      }
    }
  }

  .react-tabs {
    background: #f4f4f5;
    border-radius: 3rem;
    margin-bottom: 8rem;

    .react-tabs__tab-list {
      display: flex;
      align-items: center;
      padding: 0.2rem;
      border-bottom: 0.2rem solid #fafafa;
    }

    .react-tabs__tab {
      font-style: normal;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.3rem;
      text-align: center;
      letter-spacing: 0.05em;
      color: #121212;
      opacity: 0.35;
      width: 100%;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;

      &.react-tabs__tab--selected {
        font-style: normal;
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 3.8rem;
        text-align: center;
        letter-spacing: 0.05em;
        color: #1f1414;
        background: #fafafa;
        opacity: 1;
        border: none;
        border-top-left-radius: 3rem;
        border-top-right-radius: 3rem;
      }
    }

    .react-tabs__tab-panel {
      padding: 5.2rem 7.6rem;
    }

    .productDetailInfo {
      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.5rem;
        letter-spacing: 0.05em;
        color: #1f1414;
        margin-bottom: 1.4rem;
      }

      p,
      ul li {
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 145%;
        color: #1f1414;
        opacity: 0.75;
      }

      ul {
        padding: 0 0 2.4rem;
      }

      ul li {
        margin-bottom: 1.6rem;
      }
    }
  }

  .productDetailIndicators {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .productDetailIndicatorsChild {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 45rem;
    flex-wrap: wrap;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.5rem;
      letter-spacing: 0.05em;
      color: #1f1414;
      display: flex;
      align-items: center;
      border-bottom: 0.1rem solid rgba(31, 20, 20, 0.08);
      padding-bottom: 1.6rem;
      margin-bottom: 1.8rem;
      width: 40%;
      transition: 0.3s;

      a,
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.9rem;
        letter-spacing: 0.05em;
        color: rgba(0, 45, 135, 0.75);
        border-bottom: 0.1rem solid #002d87;
        margin-left: 1.2rem;
        white-space: nowrap;
        transition: 0.3s;
      }

      &:hover {
        color: rgba(255, 4, 4, 1);

        a,
        span {
          color: rgba(255, 4, 4, 1);
          border-bottom: 0.1rem solid rgba(255, 4, 4, 1);
        }
      }
    }
  }

  .moreParent {
    display: none;
  }

  .quantity-picker {
    display: flex;
    align-items: center;
    border: none;
  }

  @media (max-width: 767.9px) {
    .quantity-picker {
      padding: 0 1rem;
    }
    .mySwiper2 {
      height: 40rem !important;
    }
    .productRow {
      margin-top: 2.4rem;
      margin-bottom: 6.2rem;

      .swiper {
        .swiper-slide {
          height: 37rem;
        }

        .swiper-button-next,
        .swiper-button-prev {
          color: #ff0000;
        }
      }

      .productDetailImg {
        .discount {
          height: 2.6rem;
          left: 1.4rem;
          top: 1.5rem;
        }

        .badge {
          top: 1.2rem;
        }
      }

      .swiper-thumbs {
        display: none;
      }

      .productChoose {
        display: flex;
        flex-direction: column;
      }

      .productRight {
        padding-left: 0;

        .productRightPrice {
          padding-bottom: 2.4rem;
          margin-bottom: 2.8rem;

          p {
            font-size: 3.2rem;

            svg {
              width: 3.3rem;
              height: 2.8rem;
            }
          }
        }
      }

      .productChoose {
        display: flex;
        flex-direction: column-reverse;

        .productTypeParent {
          width: 100%;
        }

        .productType {
          width: 100%;
          margin-bottom: 3.2rem;

          .filter__control {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
          }

          .css-b62m3t-container {
            width: 100%;
          }
        }
      }

      .addToCartSection {
        padding: 0 0 2.4rem;
      }

      .addToCartTitle {
        padding: 3.2rem 0 0.8rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 125.1%;
        color: #000000;
        opacity: 0.65;
      }

      .quantity {
        width: 35%;
        padding: 0;
      }

      .like .heart p {
        font-size: 1.4rem;
      }

      .swap {
        padding-left: 0;
        margin-left: 0;
        border-left: 0;
      }

      .buttonSection {
        justify-content: space-between;
        flex-wrap: wrap;

        svg {
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 0.6rem;
        }
      }

      .shareSection {
        margin-top: 2.6rem;

        .rating {
          padding-left: 0;
          border-left: none;

          svg {
            width: 1.4rem;
            height: 1.4rem;
          }

          span {
            font-size: 1.3rem;
            line-height: 1.8rem;
          }
        }
      }
    }
    .react-tabs {
      margin-bottom: 6.4rem;

      .react-tabs__tab {
        height: 6rem !important;
        font-size: 1.5rem !important;
        line-height: 2rem !important;
        color: #09095c;
        background-color: transparent !important;
        padding: 0 2rem;

        &.react-tabs__tab--selected {
          font-size: 2rem !important;
          line-height: 2.7rem !important;
        }
      }

      .react-tabs__tab-list {
        overflow: auto;
      }

      .react-tabs__tab-panel {
        padding: 0 2rem 3.2rem;

        .productDetailIndicators {
          display: flex;
          flex-direction: column;

          .productDetailIndicatorsChild {
            width: 100%;
            height: 100%;

            &:last-child {
              p:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }

      .productDetailInfo {
        p {
          font-size: 1.6rem;
          line-height: 145%;
        }
      }
    }

    .productCard {
      .productCardHead {
        height: 20rem;
      }

      .discount {
        left: 1.6rem;
        top: 15.6rem;
        transition: 0.2s;
      }
    }
    .productSlide {
      margin-bottom: 3.2rem;
    }
    .swiper-pagination {
      display: flex !important;
      align-items: center;
      justify-content: center;
      bottom: 1rem;

      .swiper-pagination-bullet {
        border: 0.1rem solid #ff0404;
        box-sizing: border-box;
        background-color: transparent;
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: #ff0436;
        }
      }
    }
  }
}

.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9;

  @media (min-width: 767.9px) {
    display: none;
  }

  .modalClose {
    display: table;
    margin: 10px 10px 10px auto;
  }

  .modalContent {
    padding: 20px;
  }

  .detailsNav {
    .swiper-slide {
      height: 7rem !important;
    }

    .productDetailSwiper {
      border-radius: 1rem;
    }

    .swiper-slide-thumb-active {
      .productDetailSwiper {
        border: 1px solid red;
      }
    }
  }

  .swiper-pagination {
    display: none !important;
  }

  .mySwiper {
    height: 9rem !important;
  }

  .swiper-thumbs {
    display: block !important;
    margin-top: 0 !important;
    padding: 0 !important;

    .productDetailSwiper {
      height: 6.5rem !important;
    }
  }

  .mySwiper2 {
    height: 40rem !important;
  }
}

.orderHistory-rating{
  svg{
    width: 2rem !important;
    height: 2rem !important;
  }
}

.account {
  background: #fafafa;
  .orderDelivery {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 3.2rem 0;
    flex-direction: column;
    .orderDeliveryChild {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.1rem;
      letter-spacing: 0.08em;
      color: #121212;
      opacity: 0.85;
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      span {
        display: block;
        margin-left: 1rem;
      }
    }
    .totalPriceOrder {
      font-size: 2.4rem;
      margin-top: 1.4rem;
    }
  }
  .loadingImg {
    margin: 5.6rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      width: 4.8rem;
      height: 4.8rem;
    }
  }

  .productFav {
    position: relative;
    .compareDelete {
      position: absolute;
      left: 2rem;
      top: 2rem;
      width: 3.2rem;
      height: 3.2rem;
      z-index: 2;
      padding: 0;
      border: none;
      background-color: transparent;
      svg {
        width: 2.4rem;
        height: 2.4rem;
        g {
          transition: 0.3s;
        }
        &:hover {
          g {
            opacity: 1;
          }
        }
      }
    }
  }
  .accoutRow {
    padding: 1.6rem 0 7.2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .breadCrumb {
      margin-bottom: 3.8rem;
    }

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 125.1%;
      letter-spacing: 0.05em;
      color: #000000;
      margin-bottom: 3.2rem;
    }

    .accountSidebar {
      width: calc(50% - 28rem);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: sticky;
      top: 3.2rem;

      .accountSideBarChild {
        width: 32rem;
      }
    }

    .AccountRight {
      background: #ffffff;
      border-bottom-left-radius: 3rem;
      width: calc(50% + 28rem);
      margin-top: -1.6rem;
      min-height: 100vh;

      .select-placeholder-text {
        font-weight: 500 !important;
        font-style: normal;
        font-size: 1.8rem;
        line-height: 2.5rem;
        letter-spacing: 0.05em;
        color: #121212;
        opacity: 0.4;
        padding-bottom: 0.8rem;
      }
      .accountRightInnerOrder {
        margin-top: 7rem;
        width: 96rem;
        padding-top: 0;
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 2.8rem;
          letter-spacing: 0.08em;
          color: #121212;
          margin-bottom: 2.8rem;
          text-align: center;
        }
      }
      .accountRightInner {
        padding: 7rem 0 7.2rem 4.2rem;
        width: 70%;
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 2.8rem;
          letter-spacing: 0.08em;
          color: #121212;
          margin-bottom: 2.8rem;
          text-align: center;
        }

        .input-group {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 3.8rem;

          .input-child {
            width: 100%;

            &:last-child {
              margin-left: 8rem;
            }

            &:first-child {
              margin-left: 0;
            }

            label {
              font-style: normal;
              font-weight: 400;
              font-size: 1.3rem;
              line-height: 1.8rem;
              color: #121212;
              opacity: 0.7;
              display: block;
              // margin-bottom: .6rem;
            }

            input {
              font-style: normal;
              font-weight: 600;
              font-size: 1.8rem;
              line-height: 2.5rem;
              letter-spacing: 0.05em;
              color: #121212;
              border: none;
              border-bottom: 0.1rem solid #dedede;
              width: -webkit-fill-available;
              padding: 0 1rem;
              height: 3.8rem;
            }
          }
        }
      }
    }
  }

  .productCard {
    .trash {
      display: block !important;
    }
  }
  .orderHistory {
    padding: 0 4.2rem 7.2rem;
  }
  .orderHistory {
    .table {
      width: -webkit-fill-available;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
      padding: 0 2.4rem;

      div {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2.1rem;
        letter-spacing: 0.08em;
        color: #121212;
        opacity: 0.85;
      }
      .ordername {
        width: 18% !important;
      }

      .date {
        width: 28% !important;
      }

      .summary {
        width: 18%;
      }

      .orderstatus {
        width: 17%;
      }

      .paymentstatus {
        width: 18%;
      }

    }
    .reorderButton{
      height: 40px;
      width: 40px;
      border-radius: 100%;
      background-color: #5bc25b;
      border: none;
      svg{
        fill: #ffffff;
      }
    }
    .contentDesk {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .contentResp {
      display: none;
    }
    .MuiPaper-root {
      overflow: hidden;
      box-shadow: none;
      margin-bottom: 1.6rem;
      background-color: transparent;

      .MuiButtonBase-root {
        padding: 0 2.4rem;
        background: #f7f7f8;
        border-radius: 1.5rem;
        height: 4.4rem;
        min-height: 4.4rem;

        .MuiAccordionSummary-expandIconWrapper {
          position: absolute;
          right: 1.9rem;

          svg {
            transform: rotate(90deg);
            width: 2.8rem;
            height: 2.8rem;
          }
        }

        .MuiTypography-root {
          font-style: normal;
          font-weight: 600;
          font-size: 1.7rem;
          line-height: 2.1rem;
          letter-spacing: 0.05em;
          color: #121212;
        }
      }

      .MuiAccordionDetails-root {
        background: #f7f6f6;
        border-radius: 3rem;
        margin-top: 0.8rem;
        padding: 2.1rem 1.6rem;

        .MuiTypography-root {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          .img-container {
            width: 10rem;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.2rem;

            img {
              width: 6rem;
              height: 6rem;
              object-fit: contain;
            }
          }
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 155%;
            letter-spacing: 0.05em;
            color: #121212;
            text-transform: lowercase;
          }
          .priceOrder {
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 135.9%;
            letter-spacing: 0.05em;
            color: #1f1414;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 2.2rem;
              height: 1.8rem;
              margin-left: 0.8rem;
            }

            span {
              font-style: normal;
              font-weight: 500;
              font-size: 1.6rem;
              line-height: 155%;
              letter-spacing: 0.05em;
              color: #121212;
              opacity: 0.75;
              text-transform: lowercase;
              display: flex;
              align-items: center;
              position: relative;
              margin-left: 0.6rem;
              &::after {
                position: absolute;
                content: "";
                width: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 0.1rem;
                background-color: #ae0505;
                transform: rotate(10deg);
              }
              svg {
                width: 1.6rem !important;
                height: 1rem !important;
                margin-left: 0.2rem !important;
              }
            }
          }

          .detailDiv {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 18%;

            label {
              font-style: normal;
              font-weight: 600;
              font-size: 1.3rem;
              line-height: 155%;
              letter-spacing: 0.05em;
              color: #121212;
              opacity: 0.35;
              display: block;
              margin-bottom: 0.8rem;
            }

            p {
              font-style: normal;
              font-weight: 600;
              font-size: 1.6rem;
              line-height: 155%;
              letter-spacing: 0.05em;
              color: #121212;
              opacity: 0.75;
              width: 70%;
            }
            &:last-child {
              align-items: flex-end;
            }
          }

          .detailDivP {
            width: 40%;
          }
        }

        .detailDivParent {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
        }
        &:first-child {
          margin-top: 2.4rem;
        }

        &:last-child {
          margin-bottom: 3.2rem;
        }
      }

      .notPaid {
        color: #ae0505 !important;
      }

      .paid {
        color: #06b155 !important;
      }
    }
  }
  .rating {
    display: flex;
    align-items: center;
    margin-top: 3.2rem;
    span {
      color: #121212 !important;
      font-size: 1.5rem !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 155% !important;
      display: block;
      margin-right: 1.4rem;
      text-transform: capitalize !important;
      span {
        margin: 0;
      }
    }
    svg {
      width: 3rem !important;
      height: 3rem !important;
      path {
        fill: #1f141470;
      }
    }
  }
  &.favorites {
    .productCard {
      // .productCardLayer {
      //   // display: none !important;
      // }
      .badge {
        display: none;
      }
      .ProductCardButtons {
        width: 5.4rem !important;
        height: 5.4rem !important;
      }
    }
  }
  @media (max-width: 767.9px) {
    // background: #fff;
    .orderHistory {
      padding: 0 1.2rem 2.2rem;
    }
    .accoutRow {
      display: flex;
      flex-direction: column;
      padding-bottom: 5.2rem;
      h1 {
        font-size: 2.8rem;
        line-height: 125.1%;
        margin-bottom: 2.4rem;
      }
      .accountSidebar {
        width: -webkit-fill-available;
        position: static;
        padding: 0 1.6rem;
        justify-content: flex-start;
        // display: none;
        .accountSideBarChild {
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
        }
        .AccountSideBar {
          // display: none;
          width: auto;
          display: flex;
          width: fit-content;
          flex-direction: row;
          flex-wrap: wrap;
          a {
            border-radius: 50%;
            padding: 1.4rem;
            span {
              display: none;
            }
            svg {
              margin: 0;
            }
          }
        }
      }
      .AccountRight {
        width: 100%;
        min-height: 100%;
        background: transparent;
        .accountRightInnerOrder {
          max-width: 100%;
          width: 100%;
          margin-top: 2.4rem;
        }
        .accountRightInner {
          width: -webkit-fill-available;
          padding: 0 1.6rem;

          .title {
            text-align: left;
          }
          .input-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            .input-child {
              margin-bottom: 3.2rem;
              margin-left: 0 !important;

              input,
              .css-1fo60s0-control {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    .accountSave button {
      width: 100%;
    }
    .orderHistory {
      .table {
        display: none;
      }
      .contentDesk {
        display: none;
      }
      .MuiButtonBase-root {
        min-height: 6.8rem !important;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
      .MuiAccordionSummary-content {
        margin: 0;

        .contentRespHeight {
          display: none;
        }
        &.Mui-expanded {
          margin: 0;
          .contentRespHeight {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .MuiButtonBase-root {
        &.Mui-expanded {
          min-height: 21.5rem !important;
          height: auto;
          padding-bottom: 15px;
        }
      }
      .MuiAccordionSummary-expandIconWrapper {
        top: 2rem;
      }
      .contentResp {
        display: block;
        width: 100% !important;
        .contentRespClass {
          width: 86% !important;
          display: flex;
          flex-direction: column;
          //   margin-bottom: 2.2rem;
          margin-top: 2.2rem;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.6rem;
            letter-spacing: 0.08em;
            color: rgba(18, 18, 18, 0.85);
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            span {
              font-weight: 600;
              color: #121212;
            }
          }
        }
      }
    }
    .detailDivParent {
      flex-direction: column;
      align-items: flex-start !important;
      .detailDiv {
        padding-bottom: 0 !important;
        label {
          font-size: 1.2rem !important;
          line-height: 120% !important;
        }
      }
      .detailDivP {
        padding-bottom: 1.6rem !important;
      }
      .detailDivChild {
        display: flex;
        align-items: center !important;
        flex-direction: row !important;
        .priceOrder,
        span {
          padding-left: 1.6rem;
          font-size: 1.2rem !important;
          margin-bottom: 0.8rem;
        }
        .priceOrder {
          svg {
            width: 1.6rem !important;
            height: 1rem !important;
            margin-left: 0.2rem !important;
          }
          span {
            svg {
              width: 1.6rem !important;
              height: 1rem !important;
              margin-left: 0.2rem !important;
            }
          }
        }
      }
      div {
        width: 100% !important;

        .priceOrder {
          text-align: left;
          padding-bottom: 0 !important;
          justify-content: flex-start !important;
        }

        p {
          width: 100% !important;
        }
      }
    }
  }
}

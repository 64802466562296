.Products {
  width: 100%;
  padding-bottom: 4.8rem;

  .productsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3rem;

    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 125.1%;
      color: #000000;
    }
  }

  .categoryCard {
    background: #fff;
    border-radius: 2rem;
    display: flex;
    align-items: flex-end;
    height: 34.3rem;
    position: relative;
    margin-bottom: 1.6rem;
    width: 100%;
    overflow: hidden;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 125.1%;
      color: #1f1414;
      position: absolute;
      left: 2.4rem;
      bottom: 2rem;
    }
    .lazy-load-image-background {
      left: 0 !important;
      bottom: 0 !important;
    }
    img {
      position: absolute;
      top: -16rem;
      bottom: 0;
      left: 0 !important;
      right: 0 !important;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: 0.3s !important;
      //transform: scale(0.8);
    }

    &:hover {
      img {
        transform: scale(0.9);
      }
    }
  }
  .categoryCard {
    &:last-child {
      width: 100%;

      img {
        margin: 0;
        top: -8rem;
        right: auto;
        left: auto;
      }
    }

    // &:first-child {
    //   width: 100%;

    //   img {
    //     top: -1.6rem;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     margin: auto;
    //   }
    // }
    &:nth-child(6n + 1),
    &:nth-child(6n + 6) {
      grid-column: span 6;
    }

    &:nth-child(6n + 2),
    &:nth-child(6n + 3),
    &:nth-child(6n + 4),
    &:nth-child(6n + 5) {
      grid-column: span 3;
    }
  }

  @media (max-width: 767.9px) {
    .categoryCard {
      height: 20rem;
      &:last-child {

        img {
          top: 2rem;
        }
      }
      span {
        left: 1.2rem;
        bottom: 2rem;
        font-size: 1.6rem;
        line-height: 110%;
      }

      img {
        position: absolute !important;
        bottom: 4.5rem !important;
        max-height: 160px;
      }
    }

    .customNav {
      display: none;
    }

    .productsHeader {
      padding-bottom: 2.2rem;

      h6 {
        font-size: 2.8rem;
        line-height: 125.1%;
      }
    }

  }
}

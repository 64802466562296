.headerTop {
  .headerTopInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 0 1rem;

    .headerInnerSocial {
      display: flex;
      align-items: center;

      a {
        margin-right: 1.8rem;
        svg{
          g{
            transition: .3s;
          }
        }
        &:hover{
          svg{
            g{
              opacity: 1;
            }
          }
        }
      }
    }

    .lang {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: 0.05em;
        color: rgba(31, 20, 20, 0.35);
        margin-left: 2.4rem;
        border: none;
        background-color: transparent;
        padding: 0;
        transition: .3s;

        &:hover{
          color: rgba(31, 20, 20, 1);
        }
      }
    }
  }

  @media (max-width: 767.9px) {
    display: none;
  }
}

.headerMiddle {
  .headerMiddleContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo svg {
      height: 4.8rem;
      width: fit-content;
    }

    .headerMiddleContentRight {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        margin-left: 1.4rem;
        transition: 0.3s;

        svg {
          width: 3.5rem;
          height: 3.5rem;

          path {
            transition: 0.3s;
          }
        }

        &:hover {
          svg {
            path {
              fill: #1f1414;
            }
          }
        }
      }

      .headerProduct {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #ff0404;
        padding-left: 1.2rem;
        margin-left: 1rem;
        border-left: 0.1rem solid rgba(31, 20, 20, 0.2);
        display: flex;
        align-items: center;
        span {
          margin-right: 0.6rem;
          display: block;
        }
      }
    }
  }

  @media (max-width: 767.9px) {
    display: none;
  }
}

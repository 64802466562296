.productSlide {
    margin-bottom: 7rem;

    .productSlideHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.2rem;

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 3.6rem;
            line-height: 125.1%;
            color: #000000;
        }
    }

    @media(max-width: 767.9px) {
        .productSlideHeader {
            margin-bottom: 2.4rem;

            .title {
                font-size: 2.8rem;
                line-height: 125.1%;
            }
        }

        .customNav {
            display: none;
        }

        .swiper-slide {
            .productCard {
                margin-bottom: 0;
            }
        }
    }
}
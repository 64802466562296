.newsDetail {
  padding: 1.2rem 0 5.6rem;
  position: relative;
  // background-color: #FAFAFA;

  .news-wallp {
    position: absolute;
    right: 0;
    top: 2.4rem;
    width: 50%;
    height: 53.7rem;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    overflow: hidden;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .newsDetailTop {
    padding: 10.4rem 0 0;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 4.8rem;
      line-height: 130.6%;
      color: #000000;
      margin-bottom: 3rem;
    }

    .newsDetailHead {
      border-top: 0.08rem solid rgba(31, 20, 20, 0.1);
      padding-top: 3rem;

      .newsdetailCard {
        display: flex;
        align-items: center;
        margin-bottom: 1.6rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 148%;
        color: #000000;

        svg {
          padding-right: 1.6rem;
          width: 2.4rem;
          height: 2.4rem;

          g {
            opacity: 1;
          }
        }
      }
    }
  }

  .newsDetailContent {
    background: #ffffff;
    box-shadow: 2.5rem 7.5rem 25rem rgba(117, 107, 107, 0.1);
    border-radius: 3rem;
    max-width: 103rem;
    width: 100%;
    margin: auto;
    position: relative;
    margin-bottom: 8.8rem;

    .newsDetailInner {
      padding: 11.1rem 11rem 6.8rem;
    }

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 144%;
      color: #121212;
      margin-bottom: 2.8rem;
    }

    ul {
      width: 95%;

      li {
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 155%;
        color: #121212;
        margin-bottom: 1.8rem;
        padding-left: 3.2rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0.8rem;
          background-color: #004ceb;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
        }
      }
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 155%;
      color: #121212;
      margin-bottom: 2rem;
    }

    .newsInnerDetail {
      border-left: 0.4rem solid #b5ecce;
      padding-left: 2.4rem;
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 155%;
      color: #121212;
      margin-bottom: 6.8rem;
    }

    .img-container {
      width: 100%;
      height: 46.2rem;
      border-radius: 2rem;
      overflow: hidden;
      margin-bottom: 6.8rem;
      margin-top: 6.8rem;

      img,
      video,
      iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .shareNews {
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6.9rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 155%;
      color: #121212;
    }

    a {
      margin-left: 3.2rem;

      svg {
        width: 2rem;
        height: 2rem;
        path{
          transition: .3s;
        }
        &:hover{
          path{
            fill: rgb(255, 4, 4);
          }
        }
      }
    }
  }

  .newsCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.6rem;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 4.9rem;
      text-align: center;
      letter-spacing: 0.05em;
      color: #121212;
    }
  }

  .newsSlide {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .newsCard {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // width: 100%;
      height: 100%;
    }

    .news-card-inner {
      display: flex;
      flex-direction: column;
      width: 80%;
    }

    .time {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 148%;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 4rem;

      svg {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 1.2rem;
      }

      span {
        border-left: 0.1rem solid rgba(31, 20, 20, 0.45);
        display: flex;
        align-items: center;
        margin-left: 1.8rem;
        padding-left: 1.8rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.6rem;
        }
      }
    }

    .img-container {
      height: 46.1rem;
      border-radius: 2rem;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .layerNews {
        box-shadow: inset 39.7667px -39.7667px 39.7667px rgba(73, 67, 55, 0.046),
          inset -39.7667px 39.7667px 39.7667px rgba(255, 255, 255, 0.046);
        backdrop-filter: blur(26.246px);
        border-radius: 2rem;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 148%;
        color: #fefefe;
        opacity: 0;
        transition: 0.4s;

        svg {
          width: 4.4rem;
          height: 4.4rem;
          margin-left: 1.6rem;

          path {
            fill: #fff;
          }

          circle {
            stroke: #fff;
          }
        }
      }

      &:hover {
        .layerNews {
          opacity: 1;
        }
      }
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 129.1%;
      letter-spacing: 0.05em;
      color: #000000;
      margin-bottom: 2.2rem;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 148%;
      letter-spacing: 0.05em;
      color: #000000;
      opacity: 0.85;
      margin-bottom: 2rem;
    }
  }
  @media (max-width: 767.9px) {
    .news-wallp {
      position: static;
      width: 100%;
      height: 25.8rem;
      border-radius: 3rem;
    }
    .newsDetailTop {
      font-size: 2.8rem;
      line-height: 129.1%;
      padding-top: 2.4rem;

      h1 {
        font-size: 2.8rem;
        line-height: 129.1%;
        margin-bottom: 1.6rem;
      }
      .newsDetailHead {
        border-top: none;
        padding-top: 0;
        .newsdetailCard {
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 148%;
          margin-bottom: 1.4rem;
          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }
    .newsDetailContent {
      margin-top: -2rem;
      display: block;
      width: 100vw;
      margin-left: -50vw;
      margin-right: -50vw !important;
      position: relative;
      left: 50%;
      right: 50%;
      background: #ffffff;
      box-shadow: 2.5rem 7.5rem 25rem rgba(117, 107, 107, 0.1);
      border-radius: 3.9rem 3.4rem 3rem 3rem;
      margin-bottom: 6.2rem;
      .newsDetailInner {
        padding: 4.2rem 2.4rem;
        .title {
          font-size: 2.4rem;
          line-height: 144%;
          margin-bottom: 1.6rem;
        }
        ul {
          li {
            font-size: 1.6rem;
            line-height: 155%;
            color: #121212;
            padding-left: 2rem;
            margin-bottom: 2.4rem;
            &::after {
              background-color: #000000;
            }
          }
        }
        .img-container {
          height: 21.3rem;
          margin: 0 0 4.6rem;
        }
      }
      p {
        font-size: 1.6rem;
        line-height: 155%;
      }
    }
    .newsDetailContent {
      .newsInnerDetail {
        font-size: 1.6rem;
        line-height: 155%;
        padding-left: 1.6rem;
      }
    }
    .shareNews {
      height: 6rem;
    }
    .customNav {
      display: none;
    }
    .newsCardHeader {
      .title {
        font-size: 2.8rem;
        line-height: 3.8rem;
      }
    }
    .newsSlide {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .time {
        margin-bottom: 1.2rem;
      }
      .news-card-inner {
        width: 100%;
      }
      h2 {
        font-size: 2.4rem;
        line-height: 129.1%;
        margin-bottom: 1.6rem;
      }
      .img-container {
        height: 24.2rem;
      }
    }
  }
}

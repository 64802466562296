.AccountSideBar {
  padding: 2rem 0 !important;
  a {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.1rem;
    letter-spacing: 0.08em;
    color: rgba(18, 18, 18, 0.25);
    margin-bottom: 3.2rem;
    padding: 0 1.8rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 1.8rem;
      opacity: 0.7;

      g {
        opacity: 1;
      }

      path {
        stroke: rgba(18, 18, 18, 0.25);
      }
    }

    &.active {
      color: #ff0b0b;
      background: #ffffff;
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
      opacity: 1;
      padding: 1.3rem 1.8rem;

      svg {
        g {
          opacity: 1;
        }

        path {
          fill: #ff0b0b;
          stroke: #ff0b0b;
        }
      }
    }
  }
}

.TopSlider {
  padding: 2.4rem 0;
  height: 35.6rem;
  border-radius: 15px;
  .container {
    position: relative;
  }

  .swiper {
    height: 35.6rem;
    overflow-x: auto;
    position: static;
    border-radius: 0;

    .swiper-slide {
      background-repeat: no-repeat !important;
      background-size: cover !important;
      overflow: hidden;
      border-radius: 1rem;
    }

    .row {
      height: 100%;
      padding: 20px;
      div {
        height: 100%;
      }
    }

    img {
      width: 100%;
    }

    .swiper-pagination {
      right: -1.2rem;
      left: auto;
      width: auto;
      .swiper-pagination-bullet {
        border: 0.1rem solid #ff0404;
        width: 1rem;
        height: 1rem;
        background-color: #fff;
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: #ff0436;
        }
      }
    }
  }

  .sliderInner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 3.2rem;

    .topSliderTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 4.9rem;
      letter-spacing: 0.05em;
      color: #1f1414;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 2.4rem;
      line-height: 3.3rem;
      letter-spacing: 0.05em;
      color: #1f1414;
      width: 80%;
      margin-bottom: 2.9rem;
    }

    a,
    button {
      padding: 0 3.6rem;

      &:hover {
        background-color: #1f1414;
      }
    }
  }

  @media (max-width: 1299.9px) {
    .swiper {
      .swiper-pagination {
        right: 2.4rem;
      }
    }
  }

  @media (max-width: 767.9px) {
    padding: 2rem 0 4rem;
    height: auto;
    .swiper {
      height: 10.9rem;
      overflow: hidden;

      .sliderInner {
        padding-left: 0;
      }

      .swiper-pagination {
        bottom: -3rem;
        top: auto;
        display: flex;
        left: 2.4rem;
        justify-content: center;
        z-index: 1;
      }
      @media (max-width: 768px) {
        .swiper-pagination{
          top: 12rem;
        }
      }

      .swiper-slide {
        h4 {
          font-size: 1.4rem;
          line-height: 103.6%;
          margin-bottom: 0.6rem;
        }

        p {
          font-size: 1rem;
          line-height: 1.4rem;
          margin-bottom: 0.8rem;
        }

        button,
        a {
          background: #ff0404;
          border-radius: 30px;
          height: 2.9rem;
          width: 10rem;
          font-size: 1rem;
          line-height: 1.4rem;
          padding: 0;
        }
      }
    }
  }
}

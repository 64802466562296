.errorPage {
  padding: 10rem 0;

  .errorPageChild {
    background-image: url("../../assets/error.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 37.9rem;
  }
  .errorPage-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 37.9rem;

    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 2.8rem;
      letter-spacing: 0.03em;
      color: #121212;
      opacity: 0.45;
      margin-bottom: 1.8rem;
      text-align: center;
      margin-bottom: 3rem;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.03em;
      color: #121212;
      margin-bottom: 1.2rem;
    }
    .tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid #edebeb;
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: rgba(18, 18, 18, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3rem;
        height: 4.8rem;
        border: 0.1rem solid #dedddd;
        box-sizing: border-box;
        border-radius: 3rem;
        margin-right: 0.6rem;
        margin-bottom: 1.6rem;
      }
    }
    .moreSpan {
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.3rem;
      text-align: center;
      letter-spacing: 0.03em;
      color: #121212;
      opacity: 0.3;
      text-align: center;
      display: block;
      margin: 2.4rem 0 2rem;
    }
    .blackButton {
      width: 70%;
    }
    .blackButtonParent {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 767.9px) {
    padding: 5rem 0;
    .errorPageChild {
      min-height: auto;
      padding-top: 23.3rem;
      background-size: contain;
      background-position: 0 0;

      .errorPage-right {
        height: auto;
        min-height: auto;
        margin-top: 3.6rem;

        h2 {
          text-align: center;
          margin-bottom: 3.2rem;
        }
        p {
          text-align: center;
          margin-bottom: 3.6rem;
        }
        .tags {
          width: 100%;
          padding-bottom: 0.8rem;
          a {
            font-size: 1.4rem;
            line-height: 1.6rem;
            padding: 0 1.8rem;
            margin-right: 0.8rem;
          }
        }
        .blackButton {
          width: 100%;
          height: 4.8rem;
        }
      }
    }
  }
}

.productCard {
  background: #fff;
  border-radius: 3rem;
  position: relative;
  margin-bottom: 2.4rem;
  border: 0.1rem solid #dddcdc;

  @media (max-width: 768px) {
    .discount {
      left: 10rem;
      top: 0;
    }

  }

  .productCardHead {
    height: 25rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .swiper-pagination .swiper-pagination-bullet-active {
      background-color: red;
    }

    img {
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
      transition: 0.2s;
    }
  }

  .sku {
    margin: 2rem 0 0.5rem;
    margin-top: 0.4rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.9rem;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.65);
    transition: 0.3s;
    background-color: transparent;
    border: none;
    padding: 0;

    span {
      font-weight: bold;
    }
  }

  .svg {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .ProductCardButtons {
    background: #f6f6f7;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-right: 1.6rem;
    z-index: 2;

    &:last-child {
      margin-right: 0;
    }

    .cartClass {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 2.4rem !important;
        height: 2.4rem !important;
      }
    }

    .clickDone {
      width: fit-content;
      height: fit-content;

      svg {
        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(2),
        &:nth-child(1) {
          position: absolute;
          right: 0.6rem;
          top: 0.6rem;
          width: 1.3rem;
          height: 1.3rem;
        }

        &:last-child {
          width: 2.3rem;
          height: 2.3rem;
        }
      }
    }
  }

  .like-button:checked + .clickDone svg {
    &:nth-child(1) {
      display: none;
    }

    &:nth-child(3) {
      path {
        // stroke: rgba(7, 176, 85, 1) !important;
        fill: rgba(7, 176, 85, 1) !important;
      }
    }

    &:nth-child(2) {
      display: block;
      opacity: 1 !important;
    }
  }

  .cartClassParent {
    position: relative;

    .cartClass {
      svg {
        width: 1.2rem !important;
        height: 1.2rem !important;
        position: absolute;
        right: 1.2rem;
        top: 1.2rem;

        &:nth-child(2) {
          display: none;
        }

        &:last-child {
          position: static;
          width: 2.4rem !important;
          height: 2.4rem !important;
        }
      }
    }

    &.active {
      .cartClass {
        svg {
          &:nth-child(1) {
            display: none;
          }

          &:nth-child(2) {
            display: block;
          }

          &:nth-child(3) {
            path {
              fill: rgba(7, 176, 85, 1) !important;
            }
          }
        }
      }
    }
  }

  .clickDone.active svg {
    &:nth-child(1) {
      display: none;
    }

    &:nth-child(3) {
      path {
        // stroke: rgba(7, 176, 85, 1) !important;
        fill: rgba(7, 176, 85, 1) !important;
      }
    }

    &:nth-child(2) {
      display: block;
      opacity: 1 !important;
    }
  }

  // .like-button:checked {
  //   pointer-events: none;
  //   display: none;
  //   opacity: 1;
  // }
  .like-button {
    opacity: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &.deactive {
      pointer-events: none;
      display: none;
    }
  }

  .trash {
    position: absolute;
    left: 2.1rem;
    top: 1.6rem;
    cursor: pointer;
    display: none;

    svg {
      width: 2.6rem;
      height: 2.6rem;
    }
  }

  .productCardBody {
    padding: 0 2rem 2.8rem;
    margin-top: 20px;

    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 155%;
      letter-spacing: 0.05em;
      color: rgba(18, 18, 18, 0.75);
      height: 4.4rem !important;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 0.4rem;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 155%;
      letter-spacing: 0.05em;
      color: rgba(18, 18, 18, 0.55);
      margin: 1.5rem 0;
      max-height: 6rem;
      width: 80%;
      display: none;
      overflow: hidden;
      text-overflow: ellipsis;
      // display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    .productCardPrice {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .price {
        font-style: normal;
        font-size: 2.2rem;
        color: #1f1414;
        display: flex;
        align-items: flex-end;
        transition: 0.2s;
        position: relative;

        svg {
          width: 2.2rem;
          height: 1.8rem;
          padding-bottom: 0.6rem;
          margin-left: 0.6rem;
        }

        span {
          font-size: 1.6rem;
          margin-left: 0.3rem;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 2rem;
          position: absolute;
          bottom: -21px;

          svg {
            width: 1.4rem;
            height: 1.1rem;
            padding-bottom: 0.4rem;
            margin-left: 0.3rem;
          }

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0px;
            bottom: 0;
            margin: auto;
            background-color: #fe0303;
            width: 100%;
            height: 0.1rem;
          }

          @media (max-width: 768px) {
            &::after {
            
            }
          }

        }
      }
    }
  }

  .productCardLayer {
    opacity: 0;
    transition: 0.2s;
    transform: scale(0.6);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: scale(1);
    background: rgba(100, 97, 97, 0.4);
    border-radius: 3rem;
    z-index: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: -webkit-fill-available;
    padding-bottom: 2.4rem;

    .productCardLayerInner {
      position: absolute;
      bottom: 0rem;
      left: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  .productCardLayerIcons {
    justify-content: center;
    margin-bottom: 20px;
  }

  .rating {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 0.45;

    svg {
      width: 1.37rem;
      height: 1.37rem;
    }
  }

  &:hover {
    // img,
    // .price {
    //     transform: scale(1.04);
    // }

    .productCardLayer {
      opacity: 1;
    }
  }

  @media (max-width: 767.9px) {
    &:hover {
      .productCardLayer {
        opacity: 1;
        background-color: transparent !important;
        display: flex !important;
      }
    }
    .productCardLayer {
      top: 0;
      bottom: auto;
      height: 70%;

      label {
        display: none !important;
      }
    }
    .productCardHead {
      height: 20rem;
    }
    .productCardBottom {
      // width: 60% !important;
      justify-content: center !important;
      padding: 1.6rem 1rem 0.8rem !important;
    }
    .productCardBody {
      h6 {
        height: 3.8rem !important;
      }

      .price {
        font-size: 1.6rem !important;

        span {
          display: flex !important;
          font-size: 1.2rem !important;
          align-items: center;

          svg {
            width: 1rem !important;
            height: 0.8rem !important;
            padding-bottom: 0.2rem;
          }
        }
      }
    }
    .ProductCardButtons {
      background-color: #f6f6f7;
      width: 3.8rem !important;
      height: 3.8rem !important;

      .clickDone svg:last-child {
        width: 1.6rem !important;
        height: 1.6rem !important;
      }

      .clickDone svg:nth-child(2),
      .clickDone svg:nth-child(1) {
        width: 0.8rem !important;
        height: 0.8rem !important;
        right: 0.5rem !important;
        top: 0.8rem !important;
      }
    }
  }

  .cartClassParent {
    position: relative;

    .cartClass {
      svg {
        width: 1.2rem !important;
        height: 1.2rem !important;
        position: absolute;
        right: .7rem;
        left: auto;
        top: .6rem;

        &:nth-child(2) {
          display: none;
        }

        &:last-child {
          position: static;
          width: 2.4rem !important;
          height: 2.4rem !important;
        }
      }
    }
  }
}

.popupCard .popupContainer {
  width: fit-content;
  height: fit-content;
  background: #ffffff;
  border-radius: 3rem;
  overflow: hidden;

  .crossButton {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    cursor: pointer;

    svg {
      width: 3.4rem;
      height: 3.4rem;
    }
  }
}

.popupCard .popupCardInner {
  position: relative;
  padding: 4.8rem;
  display: flex;
  align-items: center;
  height: 40rem;

  .popupProductCardStok {
    position: relative;

    span {
      position: absolute;
      top: -4rem;
      left: 1rem;
      display: flex;
      justify-content: flex-end;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 144%;
      letter-spacing: 0.03em;
      color: red;
      margin: 2rem 0 0.6rem;
    }
  }
}

.popupCardLeft {
  background: #fff;
  border-radius: 3rem;
  border: 0.1rem solid #dddcdc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
  }
}

.popupCardRight {
  display: flex;
  flex-direction: column;
  padding-left: 4.2rem;

  .quantity-picker {
    border: none;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 144%;
    letter-spacing: 0.03em;
    color: #000000;
    margin-bottom: 1rem;
  }

  .price {
    font-style: normal;
    font-weight: 600;
    font-size: 4.7rem;
    line-height: 135.9%;
    letter-spacing: 0.05em;
    color: #1f1414;
    display: flex;
    align-items: center;

    svg {
      width: 2.6rem;
      height: 4rem;
      padding-bottom: 0.6rem;
      margin-left: 0.6rem;
    }

    span {
      font-size: 1.6rem;
      margin-left: 1.8rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;

      svg {
        width: 1.4rem;
        height: 1.1rem;
        padding-bottom: 0.4rem;
        margin-left: 0.3rem;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: #fe0303;
        width: 100%;
        height: 0.1rem;
        transform: rotate(7deg);
      }
    }
  }

  .popupButtonList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
    border-top: 0.1rem solid #eeeeee;
    padding-top: 2.8rem;

    .redButton {
      padding: 0 2.4rem;

      svg {
        path {
          fill: #fff;
        }
      }

      &:disabled {
        background: #bcbaba;
        cursor: no-drop;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .popupCard {
    .popupCardInner {
      padding: 2.8rem;
      height: auto;
      flex-direction: column;

      .popupCardLeft {
        height: 22rem;
        width: 100%;
        margin-bottom: 2.4rem;
      }

      .popupCardRight {
        width: 100%;
        padding-left: 0;

        h3 {
          font-size: 2rem;
        }

        .price {
          font-size: 1.6rem !important;

          svg {
            height: 1.2rem;
            padding-bottom: 0;
            margin-left: 0.4rem;
          }

          span {
            display: flex !important;

            svg {
              padding-bottom: 0.4rem;
            }
          }
        }

        .popupButtonList {
          padding-top: 1.2rem;
          margin-top: 1.2rem;
          display: flex;
          flex-direction: column;

          .quantity {
            width: 100%;
            margin-bottom: 1.4rem;
            margin-right: 0;
          }

          .redButton {
            width: 100%;
            padding: 0 1rem;
            font-size: 1.2rem;
          }
        }
      }

      .crossButton {
        top: 0.5rem;
        right: 0.5rem;
      }
    }

    .popupContainer {
      width: 90vw;

      .popupProductCardStok {
        position: relative;
        margin-top: 3rem;
        width: 100%;

        span {
          position: absolute;
          top: -4rem;
          left: 1rem;
          display: flex;
          justify-content: flex-end;
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 144%;
          letter-spacing: 0.03em;
          color: red;
          margin: 2rem 0 0.6rem;
        }
      }
    }
  }
  .productCard {
    .cartClassParent {
      position: relative;

      .cartClass {
        svg {
          width: 0.8rem !important;
          height: 0.8rem !important;
          right: 0.5rem !important;
          top: 0.8rem !important;

          &:last-child {
            position: static;
            width: 1.8rem !important;
            height: 1.8rem !important;
          }
        }
      }
    }
  }
}


.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0 !important;
}
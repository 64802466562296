.About {
  width: 100%;
  padding: 7.6rem 0 9.8rem;

  .aboutImg {
    height: 33.4rem;
    width: 100%;
    border-radius: 1.3rem;
    object-fit: cover;
  }

  div {
    height: 100%;
  }

  .aboutContent {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 125.1%;
      color: #000000;
      margin-bottom: 1.6rem;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 148%;
      color: #000000;
      margin-bottom: 2.8rem;
    }
  }

  @media (max-width: 767.9px) {
    padding: 3.2rem 0 7.2rem;

    .row {
      display: flex;
      flex-direction: column-reverse;

      .aboutImg {
        height: 15.5rem;
        margin-bottom: 1.6rem;
        img {
          object-fit: cover;
        }
      }

      .aboutContent {
        h6 {
          font-size: 1.8rem;
          line-height: 125.1%;
          margin-bottom: 1.2rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 148%;
        }
      }
    }

    .more svg {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}

.topInfoBar {
  .topInfoBarInner {
    background: #fbfbfb;
    border-radius: 2rem;
    padding: 1.3rem 0 2.2rem;
  }

  .xl-3 {
    .topInfoCard {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-right: 0.1rem solid rgba(31, 20, 20, 0.1);

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 2.4rem;
        line-height: 3.3rem;
        letter-spacing: 0.05em;
        color: #1f1414;
      }

      svg {
        width: 4.8rem;
        height: 4.8rem;
        margin-bottom: 0.8rem;
      }
    }

    &:last-child {
      .topInfoCard {
        border-right: 0;
      }
    }
  }

  @media (max-width: 767.9px) {
    .topInfoBarInner {
      padding: 1.2rem 2.3rem 1.5rem 2rem;
    }

    .xl-3 {
      .topInfoCard {
        border-right: none;

        svg {
          width: 2.4rem;
          height: 2.4rem;
          margin-bottom: 0.4rem;
        }

        span {
          font-weight: 300;
          font-size: 1.2rem;
          line-height: normal;
          text-align: center;
          width: 5rem;
        }
      }
    }
  }
}

.news {
  padding: 1.2rem 0 4.2rem;

  .newsTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 125.1%;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 2.8rem;
    margin-top: 3.8rem;
    display: flex;
    flex-direction: column;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 148%;
    }
  }

  .newsParent {
    .newsCard {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .news-card-inner {
      display: flex;
      flex-direction: column;
      width: 80%;
    }

    .time {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 148%;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 4rem;

      svg {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 1.2rem;
      }

      span {
        border-left: 0.1rem solid rgba(31, 20, 20, 0.45);
        display: flex;
        align-items: center;
        margin-left: 1.8rem;
        padding-left: 1.8rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.6rem;
        }
      }
    }

    .img-container {
      height: 46.1rem;
      border-radius: 2rem;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .layerNews {
        box-shadow: inset 39.7667px -39.7667px 39.7667px rgba(73, 67, 55, 0.046),
          inset -39.7667px 39.7667px 39.7667px rgba(255, 255, 255, 0.046);
        backdrop-filter: blur(26.246px);
        border-radius: 2rem;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 148%;
        color: #fefefe;
        opacity: 0;
        transition: 0.4s;

        svg {
          width: 4.4rem;
          height: 4.4rem;
          margin-left: 1.6rem;

          path {
            fill: #fff;
          }

          circle {
            stroke: #fff;
          }
        }
      }

      &:hover {
        .layerNews {
          opacity: 1;
        }
      }
    }

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 129.1%;
      letter-spacing: 0.05em;
      color: #000000;
      margin-bottom: 2.2rem;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 148%;
      letter-spacing: 0.05em;
      color: #000000;
      opacity: 0.85;
      margin-bottom: 2rem;
    }
  }

  .rowNews {
    .row {
      margin-bottom: 3.8rem;

      &:nth-child(even) {
        // display: flex;
        // flex-direction: row-reverse;
        grid-auto-flow: dense;
        direction: rtl;

        .newsCard,
        .img-container {
          direction: ltr;
        }
      }
    }
  }
  @media (max-width: 767.9px) {
    .newsTitle {
      margin: 2.4rem 0 1.3rem;
      span {
        font-size: 1.4rem;
        line-height: 148%;
      }
    }
    .newsParent {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 6.2rem !important;
      .time {
        margin-bottom: 3.2rem;
      }
      .newsCard {
        .news-card-inner {
          width: 100%;
        }
        h2 {
          font-size: 2.4rem;
          line-height: 129.1%;
          margin-bottom: 1.6rem;
        }
        p {
          font-size: 1.6rem;
          line-height: 148%;
        }
      }
      .img-container {
        height: 24.2rem;
      }
    }
  }
}

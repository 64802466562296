.News {
    margin-bottom: 10rem;
    margin-top: 10.2rem;

    .newsCard {
        width: 100%;
        overflow: hidden;
        border-radius: 10px;
        img {
            width: 100%;
            object-fit: cover;
            transition: .3s;
            border-radius: 10px;
        }
        &:hover{
            img{
                transform: scale(1.2);
                transition: .3s ease;
            }
            border-radius: 10px;
        }

    }

    .swiper-pagination {
        display: none;
    }

    @media(max-width: 767.9px) {
        margin-bottom: 3.4rem;
        margin-top: 6.4rem;

        .swiper {
            padding-bottom: 3rem;
        }

        .swiper-pagination {
            display: block;
            bottom: .4rem;

            .swiper-pagination-bullet {
                border: .1rem solid #FF0404;
                width: 1rem;
                height: 1rem;
                background-color: #fff;
                opacity: 1;

                &.swiper-pagination-bullet-active {
                    background: #FF0436;
                }
            }
        }
    }
}
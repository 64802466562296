.support {
  background: #fafafa;

  .accoutRow {
    padding: 1.6rem 0 7.2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .breadCrumb {
      margin-bottom: 3.8rem;
    }

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 125.1%;
      letter-spacing: 0.05em;
      color: #000000;
      margin-bottom: 3.2rem;
    }

    .accountSidebar {
      width: calc(50% - 24rem);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: sticky;
      top: 3.2rem;
      padding: 2.4rem 0;
      .accountSideBarChild {
        width: 38rem;
      }
    }

    .AccountRight {
      background: #ffffff;
      border-bottom-left-radius: 3rem;
      width: calc(50% + 24rem);
      margin-top: -1.6rem;
      min-height: 100vh;

      .accountRightInner {
        padding: 6.2rem 12.2rem;
        width: 70%;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 3.2rem;
          line-height: 144%;
          color: #121212;
          margin-bottom: 1.8rem;
        }

        .subTitle {
          font-style: normal;
          font-weight: 700;
          font-size: 2.4rem;
          line-height: 155%;
          color: #121212;
          margin-bottom: 1.6rem;
        }
      }
    }
  }

  .productCard {
    .trash {
      display: block !important;
    }
  }

  .orderHistory {
    .table {
      width: -webkit-fill-available;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
      padding: 0 2.4rem;

      div {
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.1rem;
        letter-spacing: 0.08em;
        color: #121212;
        opacity: 0.85;
      }

      .ordername {
        width: 18% !important;
      }

      .date {
        width: 28% !important;
      }

      .summary {
        width: 18%;
      }

      .orderstatus {
        width: 18%;
      }

      .paymentstatus {
        width: 18%;
      }
    }

    .MuiPaper-root {
      overflow: hidden;
      box-shadow: none;
      margin-bottom: 1.6rem;

      .MuiButtonBase-root {
        padding: 0 2.4rem;
        background: #f7f7f8;
        border-radius: 1.5rem;
        height: 4.4rem;
        min-height: 4.4rem;

        .MuiAccordionSummary-expandIconWrapper {
          position: absolute;
          right: 1.9rem;

          svg {
            transform: rotate(90deg);
            width: 2.8rem;
            height: 2.8rem;
          }
        }

        .MuiTypography-root {
          font-style: normal;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 2.1rem;
          letter-spacing: 0.05em;
          color: #121212;
        }
      }

      .MuiAccordionDetails-root {
        background: #f7f6f6;
        border-radius: 3rem;
        margin-top: 0.8rem;
        padding: 2.1rem 1.6rem;

        .MuiTypography-root {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .img-container {
            width: 6rem;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.2rem;

            img {
              width: 6rem;
              height: 6rem;
              object-fit: contain;
            }
          }

          .priceOrder {
            font-style: normal;
            font-weight: 500;
            font-size: 2.8rem;
            line-height: 135.9%;
            letter-spacing: 0.05em;
            color: #1f1414;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 2.2rem;
              height: 1.8rem;
              margin-left: 1.2rem;
            }
          }

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 155%;
            letter-spacing: 0.05em;
            color: #121212;
            opacity: 0.75;
          }

          .detailDiv {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 18%;

            label {
              font-style: normal;
              font-weight: 600;
              font-size: 1.3rem;
              line-height: 155%;
              letter-spacing: 0.05em;
              color: #121212;
              opacity: 0.35;
              display: block;
              margin-bottom: 0.8rem;
            }

            p {
              font-style: normal;
              font-weight: 600;
              font-size: 1.6rem;
              line-height: 155%;
              letter-spacing: 0.05em;
              color: #121212;
              opacity: 0.75;
              width: 70%;
            }
          }

          .detailDivP {
            width: 45%;
          }
        }

        &:first-child {
          margin-top: 2.4rem;
        }

        &:last-child {
          margin-bottom: 3.2rem;
        }
      }

      .notPaid {
        color: #ae0505;
      }

      .paid {
        color: #06b155;
      }
    }
  }

  .DeliveryList {
    margin-bottom: 4rem;

    h2.title {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem !important;
      line-height: 155%;
      color: #121212;
      margin-bottom: 0.8rem;
      position: relative;
      padding-left: 2.4rem;
    }

    ul {
      li {
        margin-bottom: 2rem;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: -2.4rem;
          top: 1rem;
          margin: auto;
          background: #5bc25b;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
        }
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 155%;
      color: #121212;
      padding-left: 2.4rem;
    }
  }

  .faqList {
    border-left: 0.4rem solid #b5ecce;
    padding-left: 3.2rem;
    margin-bottom: 4.6rem;

    h2.title {
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 155%;
      color: #121212;
      margin-bottom: 1.6rem;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 145%;
      color: #121212;
      opacity: 0.65;
      margin-bottom: 1.6rem;
    }
  }

  .bonusList {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 145%;
      color: #121212;
      opacity: 0.65;
      margin-bottom: 1.6rem;
    }

    ul {
      margin-top: 2.4rem;
      margin-bottom: 5rem;

      li {
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 145%;
        color: rgba(18, 18, 18, 0.65);
        margin-bottom: 1.2rem;
        position: relative;
        padding-left: 3.2rem;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 1.2rem;
          height: 0.1rem;
          background: linear-gradient(180deg, #07b055 0%, #06b155 100%);
        }
      }
    }
  }

  .bonusCards,
  .CreditCards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    .img-container {
      height: auto;
      width: 45%;
      margin-bottom: 3.4rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .CreditCards {
    .img-container {
      height: auto;
      width: 48%;
      margin-bottom: 1.6rem;
    }
  }

  .account-subtitle {
    border-top: 0.1rem solid rgba(31, 20, 20, 0.1);
    padding-top: 5.6rem;
  }
  @media (max-width: 1249.9px) {
    .accountSidebar {
      padding-left: 1.6rem;
    }
  }
  @media (max-width: 767.9px) {
    .accoutRow {
      display: flex;
      flex-direction: column;
      padding: 0 1.6rem;
      .accountSidebar {
        width: 100%;
        position: static;
        padding-left: 0;
        justify-content: flex-start;
        .AccountSideBar {
          width: auto;
          display: flex;
          width: fit-content;
          flex-direction: row;
          a {
            border-radius: 50%;
            padding: 1.4rem;
            span {
              display: none;
            }
            svg {
              margin: 0;
            }
          }
        }
        .accounSideBarResp {
          display: none;
        }
        h1 {
          margin-bottom: 2.4rem;
        }
      }
      .accountSideBarChild {
        width: 100% !important;
      }
      .AccountRight {
        width: 100%;
        min-height: auto;
        margin-top: auto;
        background-color: transparent;
        .accountRightInner {
          padding: 0;
          width: 100%;

          .title {
            font-size: 2rem;
            line-height: 144%;
            margin-bottom: 2.4rem;
          }

          .DeliveryList {
            margin-bottom: 3.2rem;
            h2 {
              font-size: 1.6rem;
              line-height: 155%;
              margin-bottom: 1.2rem;
            }
            p {
              font-size: 1.6rem;
              line-height: 155%;
            }
          }
          .faqList {
            padding-left: 1.4rem;
            margin-bottom: 3.2rem;
            p {
              font-size: 1.6rem;
              line-height: 144.5%;
            }
          }
        }
        .subTitle {
          font-size: 2rem !important;
        }
        .bonusList {
          p {
            font-size: 1.6rem;
            line-height: 144.5%;
            margin-bottom: 1rem;
          }
          br {
            height: 1rem;
          }
          ul {
            margin-bottom: 2.4rem;

            li {
              font-style: normal;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 145%;
              letter-spacing: 0.05em;
              padding-left: 2.2rem;

              &::after {
                width: 1rem;
              }
            }
          }
        }
        .account-subtitle {
          border-top: none;
          padding-top: 2.4rem;
        }
        .bonusCards,
        .CreditCards {
          display: flex;
          flex-direction: column;

          .img-container {
            width: 100%;
          }
        }
      }
    }
  }
}

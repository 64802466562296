.Application {
  .qr-code-section{
    display: none !important;
  }
  width: 100%;
  margin: 20px 0;
  .aboutImg {
    height: 33.4rem;
    width: 100%;
    border-radius: 1.3rem;
    object-fit: cover;
  }
  .row{
    background: #e52e30;
    border-radius: 10px;
    padding: 20px;
  }
  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 6;
  }

  .aboutContent {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 3rem;
      line-height: 125.1%;
      color: #FFFFFF;
      margin-bottom: 1.6rem;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 148%;
      color: #FFFFFF;
      margin-bottom: 2.8rem;
    }
  }

  @media (max-width: 767.9px) {
    padding: 3.2rem 0 7.2rem;

    .row {
      display: flex;
      flex-direction: column-reverse;

      .aboutImg {
        height: 15.5rem;
        margin-bottom: 1.6rem;
        img {
          object-fit: cover;
        }
      }

      .aboutContent {
        h3 {
          font-size: 1.8rem;
          line-height: 125.1%;
          margin-bottom: 1.2rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 148%;
        }
      }
    }

    .more svg {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}
@media (max-width: 768px) {
  .Application{
    text-align: center;
    h6{
      font-size: 2rem !important;
    }
    img{
      width: 100%;
    }
  }
}
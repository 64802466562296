.brand {
  padding-bottom: 10.3rem !important;
  padding-top: 0 !important;

  .brandHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.6rem;

    .customNav{
      display: none;
    }

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 125.1%;
      letter-spacing: 0.05em;
      color: #000000;
    }
  }

  .brandCard {
    background: #fff;
    border-radius: 2rem;
    width: 100%;
    height: 8.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain !important;
      transition: 0.3s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  @media (max-width: 767.9px) {
    padding-bottom: 6.4rem !important;
  }
}

.partners {
  .brand {
    padding-top: 6.4rem !important;
    padding-bottom: 0 !important;
  }
}
